import Select from "react-select";

const SelectBasic = ({ options, object, onChange, placeholder, defaultOption, value }) => {
  let formattedOptions;

  if (!object || object.length === 0) {
    formattedOptions = options && options.length > 0 ? options.map((text, index) => ({
      value: index,
      label: text,
    })) : [];
  } else {
    formattedOptions = object.map((oneObject, index) => {
      const { value, label } = oneObject;

      return {
        value,
        label,
        index,
      };
    });
  }

  return (
    <div className="SelectBasic">
      <Select
        options={formattedOptions}
        onChange={(selectedOption) => onChange(selectedOption.value)} // Vrátíme pouze `value`
        menuPlacement="auto"
        placeholder={placeholder}
        maxMenuHeight={200} // Maximální výška výběru
        value={formattedOptions.find((opt) => opt.value === value) || null}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: "center",
            whiteSpace: "nowrap", // Zabrání zalamování textu
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "max-content", // Automatická šířka podle obsahu
            minWidth: "40px", // Nastaví minimální šířku
          }),
          menu: (provided) => ({
            ...provided,
            whiteSpace: "nowrap",
          }),
          option: (provided) => ({
            ...provided,
            textAlign: "center",
            cursor: "pointer",
            textOverflow: "ellipsis",
          }),
        }}
        // defaultValue={defaultOption} // Nastavit výchozí možnost
      />
    </div>
  );
};

export default SelectBasic;
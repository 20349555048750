import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../../global/GlobalContext";
// import "../ColorSets/ColorSets.scss";
import useFetch_colroSet_info from "../../../hooks/Pages/UserData/useFetch_colorSet_info";
import NewRespondentResult from "../../../components/pages/UserData/NewRespondent/NewRespondent_Result";
import UserDataListFromLastPageScroll from "../../../components/completeComponents/UserData_listFrom_last_result/UserData_listFromLast_pageScroll/UserData_listFromLast_pageScroll";
import SearchFullResult from "../../../components/elements/Search_FullResult/Search_FullResult";

const NewRespondent = () => {
  const { autoShow_newRespondent } = useContext(GlobalContext);

  console.log(autoShow_newRespondent);

  const [fetch_result, setFetch_result] = useState([]);

  // new fetch
  const [page, setPage] = useState(1);
  const [limitResponse, setLimitRespose] = useState(10);
  const [search, setSearch] = useState("");

  const phaseRun = "newRespondent";

  // -------------------------------
  //custom hook
  // fetch colroSets info
  
  useFetch_colroSet_info(
    "/find_colorSets_newRespondent",
    "limit",
    limitResponse, // limit on page
    "page",
    page,
    setFetch_result,
    search
  );

  // autoShow_newRespondent
  useEffect(() => {
    if(autoShow_newRespondent?.name) {
      setFetch_result((prev) => ({
        ...prev,
        fetch_data: [
          autoShow_newRespondent,
          ...(Array.isArray(prev.fetch_data) ? prev.fetch_data : [])
        ],
      }));
    }
    
  }, [autoShow_newRespondent]);

  // ---------------------------------------

  return (
    <div className="ColorSets page">
      <div className="UserData_main">
        <section className="title">
          <h1 className="page_title">Nevyplněné diagnostiky</h1>
        </section>

        <section className="search">
          <SearchFullResult
            inputType="text"
            inputLabel="Vyhledat jméno nevyplněného respondenta"
            inputID="newRespondent_name"
            inputRegex="universal"
            state={search}
            setState={setSearch}
            wordResultKey="newRespondent_name"
            urlFetch="/find_colorSets_newRespondent"
            setFetch_result={setFetch_result}
            wordResultKey2="page"
            state2={page}
            wordResultKey3="limit"
            state3={limitResponse}
          />
        </section>

        <section className="result">
          <NewRespondentResult fetch_result={fetch_result} setFetch_result={setFetch_result} phaseRun={phaseRun} />
        </section>
      </div>

      {/* Page Scroller */}
      <section className="pageScroll">
        {fetch_result.totalPages && (
          <UserDataListFromLastPageScroll
            fetch_result={fetch_result}
            page={page}
            setPage={setPage}
            limitResponse={limitResponse}
            setLimitRespose={setLimitRespose}
          />
        )}
      </section>
    </div>
  );
};

export default NewRespondent;
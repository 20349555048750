import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../global/GlobalContext";
import "./scss/Reset_Password.scss";
import InputLinePassword from "../components/elements/inputs/InputLine/InputLine_Password/InputLine_Password";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const { urlServer } = useContext(GlobalContext);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token"); // ✅ Získání tokenu z URL

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorMSG, setErrorMSG] = useState("");

  const navigate = useNavigate();

  // --------------------------------------
  // hook

  useEffect(() => {
    setErrorMSG("");
  }, [password1, password2]);

  // ------------------------
  // f
  const btn = async (e) => {
    e.preventDefault();

    try {
      if (password1 !== password2) {
        return setErrorMSG("Nová hesla se neshodují");
      }

      if (password1.length < 8 || password1.length > 64) {
        return setErrorMSG("Nové heslo musí být mezi 8 - 64 znaky");
      }

      const response = await fetch(`${urlServer}/forget_password_change`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password_new1: password1,
          token: token,
        }),
      });
      
      const data = await response.json();

      if (data.success) {
        navigate("/");
      } 

      setErrorMSG(data.msg);
    } catch (error) {
      setErrorMSG(
        "Chyba fronendu při odesílíní: " +
          error +
          ". Kontaktujte nás Váš DMAPS."
      );
    }
  };

  // ------------------------

  return (
    <div className="Reset_Password">
      <div className="title">
        <h1>Nové heslo</h1>
      </div>

      <div className="input_box">
        <InputLinePassword
          placeholder="Nové heslo"
          value={password1}
          set={setPassword1}
          regexSetting="password"
          autoComplete="new-password"
        />
        <InputLinePassword
          placeholder="Nové heslo znovu"
          value={password2}
          set={setPassword2}
          regexSetting="password"
          autoComplete="new-password"
        />
      </div>

      <div className="error_box">
        <p>{errorMSG}</p>
      </div>

      <div className="button_box">
        <button type="submit" onClick={btn}>
          Změnit heslo
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;

import { useState } from "react";
import { ChromePicker } from "react-color";
import "./InputColor.scss";
import BtnClose from "../../btn/BtnClose/BtnClose";

const InputColorChromePicker = ({ setSelectedColor, setShowColor }) => {
    const [color, setColor] = useState("#FFFFFF");

    const handleColorChange = (color) => {
        setColor(color.hex);
    };

    const btn_setColor = () => {
        setSelectedColor(color);
        setShowColor(false);
    };

    return (
        <div className="InputColor">
            <div></div>
            <BtnClose setClose={setShowColor} />
            <h3>Vyberte barvu</h3>
            <ChromePicker
                color={color}
                onChange={handleColorChange}
                disableAlpha={true} // skryje průhlednost
            />
            <button
                className="btnColorSelect"
                onClick={btn_setColor}
                // style={{ backgroundColor: color }}
            >
                Potvrdit
            </button>
        </div>
    );
};

export default InputColorChromePicker
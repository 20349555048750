import { useState, useContext } from "react";
import "./NewTag.scss";
import { GlobalContext } from "../../../../../../../global/GlobalContext";
import InputContainer from "../../../../../../elements/inputs/InputContainer/InputContainer";
import route_post_universal from "../../../../../../../routes/global/route_post_universal";
import CompanyFindFromName from "./CompanyFindFromName/CompanyFindFromName";
import SelectWordSet from "./SelectWordSet/SelectWordSet";
// import NewCompany from "./NewCompany/NewCompany";
import CheckBox from "../../../../../../elements/checkBox/CheckBox";

const NewTag = ({
  setErrorMSG,
  error,
  newCompanyShow,
  setNewCompanyShow,
  setPhase,
  selectCompany,
  setSelectCompany,
  wordSet_select,
  setWordSet_select,
  setNewTagShow,
}) => {
  const { 
    urlServer,
    setError, 
    // setAutoHow_newTag
    setAutoShow_tag,
    admin_name,
    admin_surname,
    admin_color,
    admin_email
  } = useContext(GlobalContext);

  const [newTag, setNewTag] = useState("");
  const [jobPosition, setJobPosition] = useState("");
  const [jobURL, setJobURL] = useState("");
  const [private_tag, setPrivate_tag] = useState(true);
  const [lock_tag, setLock_tag] = useState(true);

  // ----------------------------------------------

  const btnCreateTag = async () => {
    if (newTag && selectCompany?.value) {
      try {
        const response = await route_post_universal(
          urlServer,
          "/tag_create",
          "tag_name",
          newTag,
          "job_position",
          jobPosition,
          "job_URL",
          jobURL,
          "respondnet_company_ID",
          selectCompany.value,
          "respondnet_company_Name",
          selectCompany.label,
          "wordSet_ID",
          wordSet_select.value,
          "wordSet_Name",
          wordSet_select.label,
          "private",
          private_tag,
          "lock",
          lock_tag
        );

        if (response.success) {
          console.log(response)
          setPhase(0);
          setNewTagShow(false);
          // autoShow
          setAutoShow_tag({
            admin_create: {
              // admin_ID: "",
              admin_color,
              // admin_company_ID: "",
              admin_email,
              admin_name: `${admin_name} ${admin_surname}`,
              lock: lock_tag,
              private: private_tag,
            },
            colorSet_number: 0,
            company: {
              company_ID: selectCompany.value,
              company_Name: selectCompany.label
            },
            date_create: Date.now(),
            default_wordSet: {
              wordSet_ID: wordSet_select.value,
              wordSet_Name: wordSet_select.label,
            },
            tag_job: {
              job_URL: jobURL,
              job_position: jobPosition,
            },
            name: newTag,
            newRespondent_number: 0,
            _id: response.tag_ID
          })
        } 
        
        setError(response.msg);
      } catch (err) {
        setErrorMSG("Client - Catch: " + err);
      }
    } else {
      setErrorMSG("Vyplňte všechna pole");
    }
  };

  // ----------------------------------------------

  return (
    <div className="NewTag">
      {/* Compeny Select + Create Company */}
      <CompanyFindFromName
        selectCompany={selectCompany}
        setSelectCompany={setSelectCompany}
        setError={setErrorMSG}
        error={error}
        newCompanyShow={newCompanyShow}
        setNewCompanyShow={setNewCompanyShow}
      />
      {!selectCompany.value && (<>
        <div className="or"></div>
        <button className="normalButton" onClick={() => setPhase(2)}>
          Vytvořit společnost
        </button>
      </>
        
      )}

      {/* Tag */}
      {selectCompany.value && (
        <>
          {/* New Tag Name */}
          <InputContainer
            type="text"
            label="Zadejte nový tag"
            id="newTag"
            value={newTag}
            set={setNewTag}
            regexSetting="tag"
          />
          {/* Job position */}
          <InputContainer
            type="text"
            label="Pracovní pozice"
            id="jopPosition"
            value={jobPosition}
            set={setJobPosition}
            regexSetting="tag"
          />
          {/* Job position */}
          <InputContainer
            type="text"
            label="Pracovní URL"
            id="jopURL"
            value={jobURL}
            set={setJobURL}
            regexSetting="URL"
          />

          <SelectWordSet
            wordSet_select={wordSet_select}
            setWordSet_select={setWordSet_select}
          />

          <div className="tag_private">
              <CheckBox
                value={lock_tag}
                set={setLock_tag}
                text={["Uzamčené výstupy", "Odemčené výstupy"]}
                disabled={private_tag ? true : false}
                name="lock"
              />

            <CheckBox
              value={private_tag}
              set={setPrivate_tag}
              text={["Soukromý tag", "Veřejný tag"]}
            />
          </div>

          <button className="normalButton" onClick={btnCreateTag}>
            Vyvořit tag
          </button>
        </>
      )}
    </div>
  );
};

export default NewTag;

import { useState, useContext } from "react";
import { GlobalContext } from "../../../../../global/GlobalContext";
import "./Btn_Switch_On_Another_Tag_PopUp.scss";
import SelectTagFindAllName from "../../../selects/Specific_With_Function/Select_Tag_FindAllName/Select_Tag_FindAllName";
import route_universal from "../../../../../routes/global/route_universal";

const BtnSwitchOnAnotherTagPopUp = ({
    setShow, // add from `React.cloneElement`
  name,
  tag_Name,
  respondent_ID,
  // - color_sets_unfilled - page
  set_list,
  set_one_respondent,
  // - tags - page
  setFetch_tags,
  tag_ID,
  setFetch_respondents_in_tag,
  // newRespondent list
  setFetch_result_NewRespondent_list
}) => {
  const { urlServer, setError } = useContext(GlobalContext);

  const [tagSelect, setTagSelect] = useState("");


  // --------------------------------------------------
  // f

  const btn_set = async () => {
    if (tagSelect.value) {
      try {
        const response = await route_universal(
          "PATCH",
          urlServer,
          "/switch_newRespondent_to_another_tag",
          "newRespondent_ID",
          respondent_ID,
          "newTag_ID",
          tagSelect.value
        );

        if (response.success) {
          // - pages - color_sets_unfilled
          if (typeof set_list === "function"){
            set_list((prev) => ({
              ...prev,
              fetch_data: prev.fetch_data.map((item) =>
                item._id === respondent_ID
                  ? {
                      ...item,
                      tag: response.tag,
                      tag_info: response.tag_info,
                      // tag_adminName: `${response.adminTag_create.name} ${response.adminTag_create.surname}`,
                      tag_job: response.tag_job,
                      // tag_color: response.adminTag_create.color,
                      company: response.company
                    }
                  : item
                ),
            }));
          }
          // ------------------------------------------------
          // - pages - tags
            // - tag_list
            if(typeof setFetch_tags === "function") {
              setFetch_tags((prev) => ({
                ...prev,
                fetch_data: prev.fetch_data.map((item) => {
                  // -1 in original Tag
                  if(item._id === tag_ID) {
                    return {
                      ...item,
                      newRespondent_number: item.newRespondent_number - 1,
                    }
                  }
                  // +1 in new Tag
                  if(item._id === response.tag.tag_ID) {
                    return {
                      ...item,
                      newRespondent_number: item.newRespondent_number + 1,
                    }
                  }
                  // others return 
                  return item;
                }),
            }));
            }

            // - Respondents in Tag_list
            if(typeof setFetch_respondents_in_tag === "function") {
              setFetch_respondents_in_tag((prev) => ({
                ...prev,
                fetch_data: prev.fetch_data.filter((item) =>item._id !== respondent_ID)
            }))
            } 
            
          // ------------------------------------------------

           // - oneRespondent
           if(typeof set_one_respondent === "function") {
            set_one_respondent((prev) => ({
              ...prev,
              fetch_data: {
                ...prev.fetch_data,
                tag: {
                  ...prev.fetch_data.tag,
                  _id: response.tag.tag_ID,
                  name: response.tag.tag_Name,
                },
                adminTag_create: response.tag_info,
              },
            }));
           }

           // newRespondent list over Tag
          if(typeof setFetch_result_NewRespondent_list === "function") {
            setFetch_result_NewRespondent_list((prev) => ({
              ...prev,
              fetch_data: prev.fetch_data.map((item) => {
                if(item._id === respondent_ID) {
                  return {
                    ...item,
                    tag: {
                      ...prev.fetch_data.tag,
                      tag_ID: response.tag.tag_ID,
                      tag_Name: response.tag.tag_Name,
                    },
                    // tag_color: response.tag_info.color,
                    tag_info: response.tag_info,
                    tag_job: response.tag_job
                  }
                }

                return item;
              })
            }))
          }

          // - Close popUp - PopUpMedium
          setShow(false);
        }

        setError(response.error ? response.message : response.msg);

      } catch (err) {
        setError("Client - Catch: " + err);
      }
    } else {
      setError("Nový tag nebyl vybrán");
    }
  };

  // --------------------------------------------------
  return (
    <div className="Btn_Switch_On_Another_Tag_PopUp">
      <div className="name_box">
        <h2 className="name">{name}</h2>
        <h2 className="tag_name">{tag_Name}</h2>
      </div>

      <div className="tag_select">
        <SelectTagFindAllName setTagSelect={setTagSelect} />
      </div>

      <button className="normalButton" onClick={btn_set}>
        Přesunout
      </button>
    </div>
  );
};

export default BtnSwitchOnAnotherTagPopUp;

import f_input_regex from "../../../../../../utils/elements/regex/f_input_regex";
import "./InputLine_Text_WithLabel.scss";

const InputLineTextWithLabel = ({
  type,
  label,
  id,
  value,
  set,
  regexSetting,
  autoComplete,
  readOnly,
}) => {
  const inputChange = (value) => {
    if (f_input_regex(regexSetting, value)) {
      set(value);
    }
  };

  return (
    <div className="InputLine_Text_WithLabel">
      <div className="intput_box">
        <label
          htmlFor={id}
          style={
            value || (readOnly && !value)
              ? {
                  top: "-13px",
                  left: "10px",
                  fontSize: "15px",
                }
              : {}
          }
        >
          {label}
        </label>
        <input
          className="custom-input"
          type={type}
          id={id}
          onChange={(e) => inputChange(e.target.value)}
          value={value}
          autoComplete={autoComplete}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default InputLineTextWithLabel;

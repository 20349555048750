// import React, { useEffect, useRef } from "react";
// import Chart from "react-apexcharts";
// import "../Graf/Graf.scss";
// import chartInstances from "../../../../../../../../utils/PDF/graf/f_chart_Instances";

// const GrafVertical = ({
//   id,
//   grafTitle,
//   grafTitle_bottom,
//   text,
//   // name,
//   data,
//   data2,
//   graf_height,
//   // meanings
//   // meaningsText,
//   staticTexts,
//   // number_meaningsText,
//   // setMeanings,
//   // meanings,
//   meaning_text
// }) => {
//   const chartRef = useRef(null);
  
//   // Uložení instance grafu do chartInstances
//   useEffect(() => {
    
//     chartInstances[id] = {
//       chart: chartRef.current.chart,
//       grafTitle: grafTitle,
//     };
    
//     return () => { // Odstranění instance grafu při unmountu
//       delete chartInstances[id];
//     };
//   }, [id, grafTitle]);

//   ///////////// -----------------------------

//   // Validace dat - nahrazení null/undefined hodnot na 0
// // Nahrazení null/undefined hodnot
// const sanitizedData = Array.isArray(data) ? data.map((value) => value ?? 0) : [];
// const sanitizedData2 = Array.isArray(data2) ? data2.map((value) => value ?? 0) : [];

// const seriesData = [{ name: grafTitle_bottom[0], data: sanitizedData }];

// if (sanitizedData2.length > 0) {
//   seriesData.push({ name: grafTitle_bottom[1], data: sanitizedData2 });
// }

// const allData = [...sanitizedData, ...sanitizedData2];
// const minDataValue = allData.length > 0 ? Math.min(...allData) : 0;
// const maxDataValue = allData.length > 0 ? Math.max(...allData) : 0;

// let yMin, yMax;
// // if (minDataValue >= 0) {
// //   yMin = 0;
// //   yMax = maxDataValue;
// // } else if (maxDataValue <= 0) {
// //   yMin = minDataValue;
// //   yMax = 0;
// // } else {
// //   yMin = minDataValue;
// //   yMax = maxDataValue;
// // }

// const additionalOffset = 5; // Přídavek pro zajištění prostoru na ose Y

// if (minDataValue <= -90) {
//   yMin = minDataValue - additionalOffset;
// } else if (minDataValue >= 0) {
//   yMin = 0;
// } else {
//   yMin = minDataValue;
// }

// yMax = maxDataValue > 0 ? maxDataValue : 0;

//  // ----------------------------------------------

//   return (
//     <div className="Graf" id={id}>
//       <h4>{grafTitle}</h4>
//       <Chart
//         ref={chartRef}
//         type="bar"
//         width="100%"
//         height={`${graf_height}px`}
//         series={seriesData}
//         options={{
//           xaxis: {
//             categories: text,
//             labels: {
//               formatter: function (value) {
//                 return value === 0 ? "Norma" : value;
//               },
//               style: {
//                 fontSize: "12px",
//                 colors: ["#000"],
//               },
//             },
//           },
//           yaxis: {
//             min: yMin,
//             max: yMax,
//             labels: {
//               formatter: function (value, index) {
//                 return text[index] ? text[index] : value;
//               },
//               style: {
//                 fontSize: "14px",
//                 colors: ["#000"],
//               },
//             },
//           },
//           dataLabels: {
//             enabled: true,
//             style: {
//               fontSize: "12px",
//               colors: ["#000000"], // Nastavení barvy popisků
//             },
//             // offsetY: -15, // Posun hodnot na ose Y, uprav podle potřeby
//             offsetX: 80, // Posun hodnot na ose X
//             formatter: function (value) {
//               return value.toFixed(1); // Zaokrouhlení na 1 des. místo
//             },
//           },
//           plotOptions: {
//             bar: {
//               horizontal: true, // Horizontální graf
//               dataLabels: {
//                 position: "top", // Popisky nad sloupci (bottom/center/top)
//               },
//               borderRadius: 10,
//               borderRadiusApplication: "end",
//             },
//           },
//           fill: {
//             type: "gradient",
//             gradient: {
//               shade: "dark",
//               type: "horizontal",
//               shadeIntensity: 0.5,
//               gradientToColors: undefined,
//               inverseColors: true,
//               opacityFrom: 1,
//               opacityTo: 1,
//               stops: [0, 50, 100],
//               colorStops: [],
//             },
//           },
//           annotations: {
//             xaxis: [
//               {
//                 x: 0,
//                 borderColor: "#000",
//               },
//             ],
//           },
//           tooltip: {
//             enabled: true,
//             y: {
//               formatter: function (value) {
//                 // Přidání mezer před hodnotu pro zarovnání na 10. místo
//                 const formattedValue = value.toFixed(1).toString().padStart(10, ' ');
//                 return formattedValue; // Vrací hodnotu zarovnanou s mezerami
//               },
//               title: {
//                 formatter: function (seriesName) {
//                   return seriesName; // Zachování názvu série
//                 },
//               },
//             },
//             style: {
//               fontSize: "12px",
//               colors: ["#000"], // Barva textu v tooltipu
//             },
//           },
//         }}
//       />
      
//       <div className="meaningText">
//         {/* Only if exist meaning_test - is for static text on graf5 */}
//         {
//           meaning_text && <div className="meaningText_one">
//           <p className="graf_title">{meaning_text?.title}</p>
//           <p className="graf_description" style={meaning_text?.practice ? {borderBottom: "1px solid black "} : {}}>{meaning_text?.description}</p>
//           <p>{meaning_text?.practice}</p>
//         </div>
//         }
//         {/* Static text - graf 5 */}
//         {
//           staticTexts && staticTexts.map((oneText, index) => {
//             const {title, description} = oneText;

//             return <div key={index} className="meaningText_one">
//               <p className="graf_title">{title}</p>
//               <p className="graf_description">{description}</p>
//             </div>
//           })
//         }
//         </div>
//     </div>
//   );
// };

// export default GrafVertical;


// -------------------------

import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import "../Graf/Graf.scss";
import chartInstances from "../../../../../../../../utils/PDF/graf/f_chart_Instances";

const GrafVertical = ({
  id,
  grafTitle,
  grafTitle_bottom,
  text,
  // name,
  data,
  data2,
  graf_height,
  // meanings
  // meaningsText,
  staticTexts,
  // number_meaningsText,
  // setMeanings,
  // meanings,
  meaning_text
}) => {
  // Uložení instance grafu do chartInstances
  useEffect(() => {
    return () => {
      delete chartInstances[id]; // Odstranění instance grafu při unmountu
    };
  }, [id, grafTitle]);

  ///////////// -----------------------------

  // Validace dat - nahrazení null/undefined hodnot na 0
  const sanitizedData = Array.isArray(data) ? data.map((value) => value ?? 0) : [];
  const sanitizedData2 = Array.isArray(data2) ? data2.map((value) => value ?? 0) : [];

  const seriesData = [{ name: grafTitle_bottom[0], data: sanitizedData }];

  if (sanitizedData2.length > 0) {
    seriesData.push({ name: grafTitle_bottom[1], data: sanitizedData2 });
  }

  const allData = [...sanitizedData, ...sanitizedData2];
  const minDataValue = allData.length > 0 ? Math.min(...allData) : 0;
  const maxDataValue = allData.length > 0 ? Math.max(...allData) : 0;


  let yMin, yMax;
  
  const additionalOffset = 5; // Přídavek pro zajištění prostoru na ose Y
  
  if (minDataValue <= -90) {
    yMin = minDataValue - additionalOffset;
  } else if (minDataValue >= 0) {
    yMin = 0;
  } else {
    yMin = minDataValue;
  }
  
  yMax = maxDataValue > 0 ? maxDataValue : 0;

 // ----------------------------------------------

  return (
    <div className="Graf" id={id}>
      <h4>{grafTitle}</h4>
      <Chart
        type="bar"
        width="100%"
        height={`${graf_height}px`}
        series={seriesData}
        options={{
          chart: {
            events: {
              mounted: (chart) => {
                chartInstances[id] = {
                  chart,
                  grafTitle: grafTitle,
                };
              },
            },
          },
          xaxis: {
            categories: text,
            labels: {
              formatter: function (value) {
                return value === 0 ? "Norma" : value;
              },
              style: {
                fontSize: "12px",
                colors: ["#000"],
              },
            },
          },
          yaxis: {
            min: yMin,
            max: yMax,
            labels: {
              formatter: function (value, index) {
                return text[index] ? text[index] : value;
              },
              style: {
                fontSize: "14px",
                colors: ["#000"],
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "12px",
              colors: ["#000000"], // Nastavení barvy popisků
            },
            offsetX: 20, // Posun hodnot na ose X
            formatter: function (value) {
              return value.toFixed(1); // Zaokrouhlení na 1 des. místo
            },
          },
          plotOptions: {
            bar: {
              horizontal: true, // Horizontální graf
              dataLabels: {
                position: "top", // Popisky nad sloupci (bottom/center/top)
              },
              borderRadius: 10,
              borderRadiusApplication: "end",
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
            },
          },
          annotations: {
            xaxis: [
              {
                x: 0,
                borderColor: "#000",
              },
            ],
          },
          tooltip: {
            enabled: true,
            y: {
              // formatter: function (value) {
              //   return value.toFixed(1).toString().padStart(10, ' ');
              // },
              formatter: function (value) {
                // Přidání mezer před hodnotu pro zarovnání na 10. místo
                const formattedValue = value.toFixed(1).toString().padStart(10, ' ');
                return formattedValue; // Vrací hodnotu zarovnanou s mezerami
              },
              title: {
                formatter: function (seriesName) {
                  return seriesName;
                },
              },
            },
            style: {
              fontSize: "12px",
              colors: ["#000"],
            },
          },
        }}
      />

      <div className="meaningText">
        {/* Only if exist meaning_test - is for static text on graf5 */}
        {meaning_text && (
          <div className="meaningText_one">
            <p className="graf_title">{meaning_text?.title}</p>
            <p
              className="graf_description"
              style={meaning_text?.practice ? { borderBottom: "1px solid black " } : {}}
            >
              {meaning_text?.description}
            </p>
            <p>{meaning_text?.practice}</p>
          </div>
        )}
        {/* Static text - graf 5 */}
        {staticTexts &&
          staticTexts.map((oneText, index) => {
            const { title, description } = oneText;

            return (
              <div key={index} className="meaningText_one">
                <p className="graf_title">{title}</p>
                <p className="graf_description">{description}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default GrafVertical;
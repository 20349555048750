import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../global/GlobalContext";
import route_post_universal from "../../routes/global/route_post_universal";

export const useOneColorSet = ( colorSet_ID ) => {
  const {urlServer, setLoading_user, setError } = useContext(GlobalContext);
  const [fetch_data, setFetch_data] = useState({});

  useEffect(() => {
    const fetchColorSet = async () => {
      if (!colorSet_ID) return; // Pokud není colorSet_ID, funkci nevoláme
      setLoading_user(true) // loading
      try {
        const result = await route_post_universal(
          urlServer,
          "/findOneColorSet",
          "colorSet_ID",
          colorSet_ID
        );

        if(result.success) {
          setFetch_data(result.result); // Uložení výsledků
        } else {
          setError(result.msg)
        }

      } catch (error) {
        setError("Chyba Fronendu: " + error);
      } finally {
        setLoading_user(false)
      }
    };

    fetchColorSet(); // Zavolání asynchronní funkce
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorSet_ID, urlServer]);

  return [fetch_data, setFetch_data]; // Vrátíme data, pokud chceme zpracovávat mimo hook
};
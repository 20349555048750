import { useState, useContext, useEffect } from "react";
import "./scss/Ceo_Registration.scss";
import { GlobalContext } from "../global/GlobalContext";

import CeoRegistrationCode from "../components/pages/Login/Ceo_Registration/Ceo_Registration_Code/Ceo_Registration_Code";
import CeoRegistrationForm from "../components/pages/Login/Ceo_Registration/Ceo_Registration_Form/Ceo_Registration_Form";

const CEORegistration = () => {
  const { token_active, urlServer, setError } = useContext(GlobalContext);
  const [front_error, setFront_Error] = useState("");
  const [fetch_data, setFetch_data] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log(fetch_data);

  // ----------------------------------
  useEffect(() => {
    const logout = async () => {
      try {
        // Získání CSRF tokenu, pokud je potřeba (pouze pro změnové požadavky)
        let csrfToken;
        const csrfResponse = await fetch(`${urlServer}/csrf-token`, {
          credentials: "include",
        });
        const csrfData = await csrfResponse.json();
        csrfToken = csrfData.csrfToken;
        // -----------------------------------

        await fetch(`${urlServer}/refreshToken-logOut`, {
          method: "POST",
          credentials: "include", // Zajistí, že cookies budou zahrnuty v požadavku
          headers: {
            "Content-Type": "application/json",
            ...(csrfToken ? { "X-CSRF-Token": csrfToken } : {}), // Přidání CSRF tokenu, pokud je k dispozici
          },
        });

        setError("Na shledanou");
      } catch (error) {
        setError("Chyba při odhlašování:", error);
      } finally {
        localStorage.clear();
        window.location.reload();
        setLoading(false);
      }
    };

    if (token_active) {
      setLoading(true);
      logout();
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------

  return (
    <div
      className="Ceo_Registration"
      style={
        fetch_data?._id
          ? {
              overflowY: "scroll",
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
            }
          : {}
      }
    >
      {!loading && (
        <>
          <div className="title">
            <h1>Registrace vedoucích společností</h1>
          </div>

          {fetch_data?._id ? (
            <CeoRegistrationForm
              fetch_data={fetch_data}
              setFront_Error={setFront_Error}
            />
          ) : (
            <CeoRegistrationCode
              setFront_Error={setFront_Error}
              setFetch_data={setFetch_data}
            />
          )}

          <p className="error">{front_error}</p>
        </>
      )}
    </div>
  );
};

export default CEORegistration;

import "./scss/Login.scss";
import { useLocation } from "react-router-dom";
import logo_dmaps from "../img/logo/dmaps/logo_dmaps_orange.png"

import LoginForm from "../components/pages/Login/Login_Form/Login_Form";
import CEORegistration from "./Ceo_Registration";
import ResetPassword from "./Reset_Password";

const Login = () => {

  const location = useLocation(); // Získání aktuální cesty

  // Kontrola, zda se nacházíme na stránce /admin_data nebo podstránkách
  const isCEORoute = location.pathname.startsWith("/registration");
  const isResetPassword = location.pathname.startsWith("/reset-password");

console.log(isCEORoute)
  return (
    <div className="Login">
      {/* Rotate logo */}
      <img className="logo_rote" src={logo_dmaps} alt="Logo DMAPS" />
      <form className="form">
        {/* <img className="logo" src={logo_dmaps} alt="Logo DMAPS" /> */}

        {/* CEO registration */}
        {
          isCEORoute && <CEORegistration />
        }

        {/* Password change */}
        {
          isResetPassword && <ResetPassword />
        }
        {/* Login */}
        {
          !isCEORoute && !isResetPassword && <LoginForm />
        }

      </form>
    </div>
  );
};

export default Login;

import "./LogOut.scss";
import { useContext } from 'react';
import { GlobalContext } from '../../../GlobalContext';

const LogOut = () => {
  const { urlServer, setError } = useContext(GlobalContext);

  const btnLogOut = async () => {
    try {
        // Získání CSRF tokenu, pokud je potřeba (pouze pro změnové požadavky)
        let csrfToken;
        const csrfResponse = await fetch(`${urlServer}/csrf-token`, { credentials: 'include' });
        const csrfData = await csrfResponse.json();
        csrfToken = csrfData.csrfToken;
  // -----------------------------------

      await fetch(`${urlServer}/refreshToken-logOut`, {
        method: 'POST',
        credentials: 'include', // Zajistí, že cookies budou zahrnuty v požadavku
        headers: {
          'Content-Type': 'application/json',
          ...(csrfToken ? { 'X-CSRF-Token': csrfToken } : {}), // Přidání CSRF tokenu, pokud je k dispozici
        },
      });

      setError("Na shledanou");
    } catch (error) {
      setError("Chyba při odhlašování:", error);
    } finally {
      localStorage.clear();
      window.location.reload()
    }
  };

  return (
    <div className="LogOut">
      <button onClick={btnLogOut}>Odhlásit</button>
    </div>
  );
}

export default LogOut;
import { useState } from "react";
import "./Login_Form.scss";
import InputLineText from "../../../elements/inputs/InputLine/InputLine_Text/InputLine_Text";
import InputLinePassword from "../../../elements/inputs/InputLine/InputLine_Password/InputLine_Password";
import BtnLogin from "./BtnLogin/BtnLogin";
import LoginForgetPassword from "./Login_ForgetPassword/Login_ForgetPassword"; // Forget Password
import useComponentEnterSubmit from "../../../../hooks/form/useComponent_EnterSubmit";

const LoginForm = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [error, setError] = useState("");
  const [forget_password, setForget_password] = useState(false); // forget password

  // -------------------------
  // hook
  
  useComponentEnterSubmit(); // enter

  // -------------------------
  return (
    <div className="Login_Form">
      {/* Ľogin form */}
      {!forget_password ? (
        <>
          <div className="title">
            <h1>Portál</h1>
          </div>

          <div className="input_box">
            <InputLineText
              type="email"
              placeholder="E-mail"
              value={loginEmail}
              set={setLoginEmail}
              regexSetting="email"
              autoComplete="email"
            />
            <InputLinePassword
              placeholder="Heslo"
              value={loginPassword}
              set={setLoginPassword}
              regexSetting="password"
              autoComplete="current-password"
            />
          </div>
          <button
            className="btn_forgot_password"
            onClick={() => setForget_password(true)}
          >
            Zapoměli jste heslo?
          </button>
          <div
            className="error_box"
            style={{ color: error === "Vítejte" ? "green" : "red" }}
          >
            <p>{error}</p>
          </div>
          <div className="button_box">
            <BtnLogin
              loginEmail={loginEmail}
              loginPassword={loginPassword}
              setErrorMSG={setError}
            />
          </div>
        </>
      ) : (
        <LoginForgetPassword btnBack={() => setForget_password(false)} />
      )}
    </div>
  );
};

export default LoginForm;

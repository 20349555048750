import { useEffect } from "react";

const useComponentEnterSubmit = () => {
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();

        const btn = document.querySelector("button[type='submit']");
        if (btn) {
          btn.click();
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
};

export default useComponentEnterSubmit;
import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../../../../global/GlobalContext";
import "./NavUserData.scss";
import { FaUser, FaLock } from "react-icons/fa";
import { BsClipboardCheckFill, BsClipboardXFill } from "react-icons/bs";
import { FaTag } from "react-icons/fa";
import { RiCommunityLine } from "react-icons/ri";
import { RiSettings4Fill } from "react-icons/ri";
import { FaChalkboardTeacher } from "react-icons/fa"; // consultant
import BtnNewRespondent from "../BtnNewRespondent/BtnNewRespondent";

import PopupMedium from "../../../elements/popUp/PopUp_Medium/PopUp_Medium";
import UserSetting from "./User_Setting/User_Setting";
import ConsultantsWindow from "./Consultants_Window/Consultants_Window";


const NavUserData = () => {
  const { admin_name, admin_surname, admin_email, admin_rank, admin_color, admin_position_name } =
    useContext(GlobalContext);

    const [showUserSetting, setShowUserSetting] = useState(false);
    const [showActiveConsultant_InCompany, setShowActiveConsultant_InCompany] = useState(false);

  return (
    
    <nav className="NavUserData">
      <div className="NavUserData_main">
        {
          // Only for admin 0, 1, 2
          admin_rank < 3 && (
            <div className="admin_change">
              <NavLink to="/admin_data" className="normalButton">
                <div className="hover_checkout">
                  <FaLock />
                  <p className="hover_text">Admin</p>
                </div>
              </NavLink>
            </div>
          )
        }

        {/*  Create NewRespndent */}
        <div className="newRespondent"
          style={{ paddingTop: ![0, 1, 2, 3, 4].includes(admin_rank) ? "30px" : "15px" }}
        >
          {
            [0,1,2,3,4].includes(admin_rank) && <BtnNewRespondent />
          }
          
        </div>

        <div className="nav-box">

          {/* ColorSets */}
          <NavLink
            to="color_sets"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <BsClipboardCheckFill className="icon" /> Vyplněné diagnostiky
            <div className="colorBox colorBox_top"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorBox colorBox_bottom"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorWhite"></div>
          </NavLink>

          {/* NewRespondent */}
          <NavLink
            to="color_sets_unfilled"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <BsClipboardXFill className="icon" /> Nevyplněné diagnostiky
            <div className="colorBox colorBox_top"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorBox colorBox_bottom"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorWhite"></div>
          </NavLink>

          {/* Tags */}
          <NavLink
            to="tags"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <FaTag className="icon" /> Tagy
            <div className="colorBox colorBox_top"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorBox colorBox_bottom"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorWhite"></div>
          </NavLink>

          {/* Company */}
          {
            // only Novitim Now
            admin_rank <= 4 && 
            <NavLink
              to="companies"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <RiCommunityLine className="icon company_icon"/> Společnosti
              <div className="colorBox colorBox_top"></div>{" "}
              {/* Testovací čtvereček */}
              <div className="colorBox colorBox_bottom"></div>{" "}
              {/* Testovací čtvereček */}
              <div className="colorWhite"></div>
            </NavLink>
          }
          

          {/* Respondents search */}
          <NavLink
            to="users_data"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <FaUser className="icon" /> Vyplnění respondenti
            <div className="colorBox colorBox_top"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorBox colorBox_bottom"></div>{" "}
            {/* Testovací čtvereček */}
            <div className="colorWhite"></div>
          </NavLink>
        </div>
      </div>

      
      <div className="NavUserData_bottom">
        {/* Consultans - only 10 rank */}
        { admin_rank === 10 &&
          <div className="consultant">
            <button className="btn_consultants" onClick={() => setShowActiveConsultant_InCompany(true)}>
              <p>Firemní konzultanti</p>
              <FaChalkboardTeacher title="Nastavení uživatele" />
            </button>
          </div>
        }
        {
          showActiveConsultant_InCompany 
          && <PopupMedium setClose={setShowActiveConsultant_InCompany} title="Konzultanti firmy"  >
            <ConsultantsWindow />
          </PopupMedium>
        }
        
        {/* User Settings ----------------- */}
        <div
          style={{ border: `5px solid ${admin_color}` }}
          className="admin_details"
          title="Změna hesla"
          onClick={() => setShowUserSetting(true)}
        >
          <div className="admin_data">
            <p className="name">{admin_name + " " + admin_surname}</p>
            <p className="email">{admin_email}</p>
            <p className="rank">{admin_position_name}</p>
          </div>
          <RiSettings4Fill />
        </div>
      </div>
      {
        showUserSetting && <PopupMedium setClose={setShowUserSetting} title="Nastavení uživatele" >
          <UserSetting />
        </PopupMedium>
      }
    </nav>
  );
};

export default NavUserData;

import React from "react";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { setHours, setMinutes, setSeconds, setMilliseconds } from "date-fns";
import { cs } from "date-fns/locale";

const SelectMUIDate = ({ selectedDate, setSelectedDate }) => {
    const handleDateChange = (newDate) => {
        if (newDate) {
            // Nastavení půlnoci v lokální časové zóně
            const localMidnight = setHours(
                setMinutes(setSeconds(setMilliseconds(newDate, 0), 0), 0),
                0
            );

            // Převod na UTC
            const utcDate = new Date(
                localMidnight.getTime() - localMidnight.getTimezoneOffset() * 60000
            );

            setSelectedDate(utcDate);
            console.log("Vybrané datum (UTC půlnoc):", utcDate.toISOString());
            console.log("Vybrané datum (lokální čas):", localMidnight.toLocaleString("cs-CZ"));
        }
    };

    return (
        <div className="Select_MUI_Date">
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={cs}>
                <DatePicker
                    label="Vyberte datum"
                    value={selectedDate}
                    onChange={handleDateChange}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </div>
    );
};

export default SelectMUIDate;

import { useState } from "react";
import "./Consultants_Window.scss";
import ConsultantsNew from './Consultants_New/Consultants_New';
import { IoMdPersonAdd } from "react-icons/io";

// list
import CompanyConsultansList from "./CompanyConsultans_List/CompanyConsultans_List";

const ConsultantsWindow = ({
  company_ID // from OneCompany
}) => {
  const [show_addConsultant, setShow_addConsultant] = useState(false);
  const [companyConsulants_list, setCompanyConsulants_list] = useState(null);

  return (
    <div className="Consultants_Window">
      {/* New Consultant */}
      <button 
        className="button_blue btnShow_addConstulant" 
        onClick={() => setShow_addConsultant(!show_addConsultant)}
        style={{ marginBottom: show_addConsultant ? "0px" : "20px" }}
      >
        <p>{show_addConsultant ? "Skrýt" : "Přidat Konzultanta"}</p>
        {
          !show_addConsultant && <IoMdPersonAdd />
        }
      </button>
      {
        show_addConsultant && <ConsultantsNew 
          setShow_addConsultant={setShow_addConsultant} 
          // auto set (add new consulant to list)
          setCompanyConsulants_list={setCompanyConsulants_list}
          // from OneCompany
          company_ID={company_ID}
        />
      }
      
      {/* Consulant List */}
      <CompanyConsultansList 
        companyConsulants_list={companyConsulants_list} 
        setCompanyConsulants_list ={setCompanyConsulants_list}
        // from OneCompany
        company_ID={company_ID}
      />
    </div>
  )
}

export default ConsultantsWindow
import { useEffect, useContext } from "react";
import localStorage_set from "../utils/localStorage/localStorage_set";
import { GlobalContext } from "../global/GlobalContext";

/**
 * @useRefreshToken
 * ----------------
 * is autoRefresh logic
 * @activeToken : 15min  /  @refreshToken : 24h
 * -----------
 * - every 10 min
 * - - control @active_time (is iat accessToken)
 * - every new click on page if is not active
 * - - control @active_time (is iat accessToken) need new refresh
 */


  // ----------------------------------------------
const useRefreshToken = () => {
  const { g_localStorage_loading, urlServer, token_active, setToken_active, active_time, setActive_time } =
    useContext(GlobalContext);

  // f - delete
  const delete_tokenData = () => {
    document.cookie =
      "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; samesite=strict;";

    document.cookie =
    "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; samesite=strict;";

    console.log("Delete")

    setToken_active(false);
    setActive_time(0);
    localStorage.clear();
    window.location.reload();
  };

  // f - refresh
  const refreshToken = async (refreshType) => {
    console.log("Refresh")
    try {
        // Získání CSRF tokenu, pokud je potřeba (pouze pro změnové požadavky)
        let csrfToken;
        const csrfResponse = await fetch(`${urlServer}/csrf-token`, { credentials: 'include' });
        const csrfData = await csrfResponse.json();
        csrfToken = csrfData.csrfToken;

    // -----------------------------------
      const response = await fetch(urlServer + "/refresh-token", {    
        method: "POST",
        credentials: "include", // Tento parametr zajistí, že HttpOnly cookies jsou odesílány s požadavkem
        headers: {
          ...(csrfToken ? { 'X-CSRF-Token': csrfToken } : {}), // Přidání CSRF tokenu, pokud je k dispozici
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          active_time,
        }),
      });

      console.log("Refresh prosel - " + refreshType)
  
      if (response.ok) {
        const data = await response.json();
        console.log("Refresh Nacren data")
  
        // Uložení nového active_time tokenu do localStorage
        const iat = { active_time: data.active_time };
        localStorage_set(iat, "active_time");
  
        // Nastavení nového access tokenu v GlobalContext
        setActive_time(data.active_time);
  
        // Logování pro refresh
        const currentTime = Date.now();
        const timeUntilExpiration = (data.active_time * 1000 + 15 * 60 * 1000) - currentTime; // čas zbývající do vypršení tokenu
        const minutesUntilExpiration = (timeUntilExpiration / 1000 / 60).toFixed(2); // zbývající minuty
  
        console.log(
          `Refresh token proveden ${
            refreshType === "auto" ? "automaticky" : "událostí viditelnosti"
          }.`
        );
        console.log(
          `Zbývalo ${minutesUntilExpiration} minut do vypršení tokenu.`
        );
      } else {
        // Pokud selže refresh tokenu, odhlásit uživatele
        delete_tokenData();
      }
    } catch (err) {
      delete_tokenData();
      console.log("Refresh neprosel")
    }
  };

  // ----------------------------------------------------------
  // hooks


  // Obnovení tokenu každých 10 minut
  useEffect(() => {
    if (g_localStorage_loading === 1 && !token_active) {
      return delete_tokenData();
    } else if (g_localStorage_loading === 1 && token_active) {
      const now = Date.now();
      const tokenExpirationTime = active_time * 1000 + 15 * 60 * 1000; // Převod expiračního času na milisekundy
      const timeUntilExpiration = tokenExpirationTime - now;

      if (
        (timeUntilExpiration < 5 * 60 * 1000) && token_active && document.visibilityState === 'visible'
      ) {
        refreshToken("AutoRefresh - IF"); // Refresh při změně viditelnosti stránky
        console.log("Auto");
      } 



    const intervalId = setInterval(() => {
      refreshToken("auto"); // Auto refresh
      console.log("Auto token refresh every 10 minutes");
    }, 4.9 * 60 * 1000); // Obnovení každých 10 minut

    return () => clearInterval(intervalId); // Vyčištění intervalu při odpojení komponenty
  }

    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_time]);


  // -------------------------------------------------------------
  // Dekódování tokenu, pokud existuje

  if (token_active) {
    // info pro mě
    const tokenExpirationTime = active_time * 1000 + 15 * 60 * 1000; // Převod expiračního času na milisekundy
    const currentTime = Date.now(); // Aktuální čas v milisekundách
    const timeUntilExpiration = (tokenExpirationTime - currentTime) / 1000 / 60; // Převod do minut

    if(timeUntilExpiration <= 0) { // delete if login (24h logout)
      delete_tokenData();
    }

    console.log(
      "Token expiration time in minutes: " +
        timeUntilExpiration.toFixed(2) +
        " minutes"
    );
  }

  // -------------------------------------------------------------
  useEffect(() => {
    if (g_localStorage_loading === 1 && !token_active) {
      return delete_tokenData();
    }
   
    else if (g_localStorage_loading === 1 && token_active) {
      const intervalId = setInterval(() => {
        const now = Date.now();
        const tokenExpirationTime = active_time * 1000 + 15 * 60 * 1000; // Převod expiračního času na milisekundy
        const timeUntilExpiration = tokenExpirationTime - now;
    
        if (timeUntilExpiration <= 0) {
          // Pokud je čas do expirace záporný nebo nulový, odhlásit uživatele
          console.log("Token expired, logging out");
          delete_tokenData(); // Odhlášení uživatele (nebo jiná funkce pro odhlášení)
        } else if (
          timeUntilExpiration < 5 * 60 * 1000 &&
          token_active &&
          document.visibilityState === 'visible'
        ) {
          refreshToken("AutoRefresh - Interval Check"); // Refresh, pokud zbývá méně než 5 minut a stránka je viditelná
          console.log("Auto token");
        }
      }, 4.9 * 60 * 1000); // Kontrola každých 4.9 minuty
    
      return () => clearInterval(intervalId); // Vyčištění intervalu při odpojení komponenty
    }
    
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_time]);

  // ---------------------------------

  // Obnovení tokenu při opětovné viditelnosti stránky (visibilitychange - 3 events)
  useEffect(() => {
    // if (g_localStorage_loading === 1 && !token_active) {

    //   if(login_screen_active) {
    //     console.log("tudy")
    //     return;
    //   } else {
    //     console.log("ne")
    //     return delete_tokenData();
    //   }
    // } else {
    //   console.log("tada")
    // }

    if (g_localStorage_loading === 1 && !token_active) {
      return delete_tokenData();
    } else if (g_localStorage_loading === 1 && token_active) {
      const handleVisibilityChange = () => {
        const now = Date.now();
        const tokenExpirationTime = active_time * 1000 + 15 * 60 * 1000; // Převod expiračního času na milisekundy
        const timeUntilExpiration = tokenExpirationTime - now;
  
        // Obnovení tokenu, pokud zbývá méně než 10 minut
        if (timeUntilExpiration <= 0) {
          // Pokud je čas do expirace záporný nebo nulový, odhlásit uživatele
          console.log("Token expired, logging out");
          delete_tokenData(); // Odhlášení uživatele (nebo jiná funkce pro odhlášení)
        } else if (
          document.visibilityState === "visible" 
          && timeUntilExpiration < 5 * 60 * 1000
        ) {
          refreshToken("event"); // Refresh při změně viditelnosti stránky
          console.log("Token refreshed Page Change");
        } else {
          const minutesUntilExpiration = (
            timeUntilExpiration /
            1000 /
            60
          ).toFixed(2); // zbývající minuty
          console.log(
            "Token stále platný, zbývá: " + minutesUntilExpiration + " minut"
          );
        }
      };
  
      document.addEventListener("visibilitychange", handleVisibilityChange);
  
      return () =>
        document.removeEventListener("visibilitychange", handleVisibilityChange);
    }

    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_time]);

  // ---------------------------------

  // Přidání obnovení tokenu při opuštění nebo zavření stránky (beforeunload)
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Provede refresh tokenu před opuštěním stránky
  //     refreshToken("beforeunload");
  //     console.log("Token refreshed due to page unload");

  //     // Pokud bys chtěl zobrazit varovný dialog při opuštění stránky
  //     event.preventDefault();
  //     event.returnValue = ""; // Toto způsobí, že prohlížeč zobrazí varování při opouštění stránky
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token]);
};

export default useRefreshToken;

import { useState, useContext } from "react";
import { GlobalContext } from "../../../../../../global/GlobalContext";
import "./Password_Change.scss";
import InputPassword from "../../../../../elements/inputs/InputPassword/InputPassword";
import route_universal from "../../../../../../routes/global/route_universal";

const PasswordChange = ({ setClose }) => {
  const { urlServer } = useContext(GlobalContext);

  const [actual, setActual] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [errors, setErrors] = useState("");

  const btn_change = async (e) => {
    e.preventDefault();

    try {
      if(!actual || !password1 || !password2) {
        return setErrors("Vyplňte všechna pole")
      }
      if(password1 !== password2) {
        return setErrors("Nová hesla se neshodují")
      }
      if(password1.length < 8 || password1.length > 64) {
        return setErrors("Nové heslo musí mít mezi 8 - 64 znaky")
      }
      
      const response = await route_universal(
        "PATCH",
        urlServer,
        "/password_change",
        "password_old",
        actual,
        "password_new1",
        password1,
        "password_new2",
        password2
      );
  
      if (response.success) {
        setClose(false);
      } else {
        setErrors(response.error ? response.message : response.msg);
      }

    } catch (err) {
      setErrors("Chyba frontendu: " + err + ". Kontaktujte nás, Váš DMAPS")
    }
  };

  return (
    <form className="Password_Change" onSubmit={btn_change}>
      <h2>Změna hesla</h2>
      <InputPassword
        type="text"
        label="Původní heslo"
        id="actualPassword"
        value={actual}
        set={setActual}
        regexSetting="password"
        autoComplete="off"
      />
      <InputPassword
        type="text"
        label="Heslo - nové"
        id="password1"
        value={password1}
        set={setPassword1}
        regexSetting="password"
        autoComplete="new-password"
      />
      <InputPassword
        type="text"
        label="Heslo - nové znovu"
        id="password2"
        value={password2}
        set={setPassword2}
        regexSetting="password"
        autoComplete="new-password"
      />
      <p>{errors}</p>
      <button className="normalButton" type="submit">
        Změnit heslo
      </button>
      
    </form>
  );
};

export default PasswordChange;

import { useEffect, useContext } from "react";
import { GlobalContext } from "../../../../../../global/GlobalContext";
import route_universal from "../../../../../../routes/global/route_universal";
import "./CompanyConsultans_List.scss";
import BtnDelete from "../../../../../elements/btn/Btn_Delete/Btn_Delete";

const CompanyConsultansList = ({ 
    companyConsulants_list, 
    setCompanyConsulants_list,
    // from OneCompany
    company_ID
}) => {
  const { setLoading_user, urlServer, setError } = useContext(GlobalContext);
  console.log(companyConsulants_list)

  // ----------------------------------
  // hook

  useEffect(() => {
    const fetch_data = async () => {
      setLoading_user(true);

      try {
        const response = await route_universal(
          "POST",
          urlServer,
          "/find_allCompenyConsultants",
          "for_company_ID",
          company_ID,
        );

        if (response.success) {
          setCompanyConsulants_list(response.fetch_data);
        } else {
          setError(response.error ? response.message : response.msg);
          setCompanyConsulants_list([]);
        }

      } catch (err) {
        setCompanyConsulants_list([]);
        setError("Chyba frontEndu: " + err + ". Kontaktujte nás. Váš DMAPS.");
      } finally {
        setLoading_user(false);
      }
    };

    if(!companyConsulants_list) {
        fetch_data();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ------------------------------------------------
  // - f

  // delete companyConsultant
  const f_companyConsultant_delete = async (companyConsultant_ID, setShow_conformation) => {
    try {
      const response = await route_universal(
        "PATCH",
        urlServer,
        "/delete_companyConsultant_for_company",
        "companyConsultant_ID",
        companyConsultant_ID,
        "body_company_ID",
        company_ID,
      )
  
      if(response.success) {
        console.log(response.msg)
        setCompanyConsulants_list((prev) => 
          prev.filter(item => item.consultant._id !== companyConsultant_ID)
        )
        setShow_conformation(false); // Zavře potvrzovací dialog
      } 

      setError(response.error ? response.message : response.msg);

    } catch (err) {
      setError("Chyba Fronendu: " + (err) + ". Kontaktujte nás. Váš DMAPS.")
    }
  }

  // ------------------------------------------------

  return (
    <div className="CompanyConsultans_List">
      <h2>Celofiremní konzulanti</h2>

      <div className="consulants_list">
        {
            companyConsulants_list?.length > 0 && companyConsulants_list.map((one, index) => {
                // const {_id, name, surname, email, color, phone} = one;
                const {_id, consultant, admin_add_consultant, admin_companyAdd_consultant} = one;
                  // consultant
                  const {name, surname, email, color, phone} = consultant;
                  const {admin_name, admin_surname, admin_email, admin_phone} = admin_add_consultant;
                  const {company} = admin_companyAdd_consultant;

                return <div 
                  className="consulant" 
                  key={_id || index}
                  title=
                  {`Přidal: ${admin_name} ${admin_surname}
    společnost: ${company}
    tel.: ${admin_phone}
    e-mail: ${admin_email}` 
                  } 
                  >
                    <div className="companyConsultant_data">
                      <div className="name_box">
                          <p style={{backgroundColor: color}} className="color" title="Barva konzultanta" ></p>
                          <p className="name">{name} {surname}</p>
                      </div>
                      <div className="contact">
                          <div className="email_box">
                              <p className="title">E-mail:</p>
                              <p>{email}</p>
                          </div>
                          <div className="phone_box">
                              <p className="title">Telefon:</p>
                              <p>{phone}</p>
                          </div>
                      </div>
                    </div>
                    <div className="companyConsultant_delete">
                      <BtnDelete 
                        title="Smazat firemního konzultanta"
                        h1="Smazat firemního konzultanta" 
                        name={`${name} ${surname}`} 
                        text="Jste si jistí, že chcete smazat firemního konzultanta?" 
                        // btn_yes={() => f_companyConsultant_delete(consultant._id)}
                        btn_yes={(setShow_conformation) => f_companyConsultant_delete(consultant._id, setShow_conformation)}
                      />
                    </div>
                </div>
            })
        }
      </div>
    </div>
  );
};

export default CompanyConsultansList;

import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../../../../global/GlobalContext";
import "./Login_ForgetPassword.scss";
import InputLineText from "../../../../elements/inputs/InputLine/InputLine_Text/InputLine_Text";
import route_universal from "../../../../../routes/global/route_universal";
import BtnBack from "../../../../elements/btn/Btn_Back/Btn_Back";

const LoginForgetPassword = ({btnBack}) => {
  const { urlServer } = useContext(GlobalContext);
  const [loginEmail, setLoginEmail] = useState("");
  const [errorMSG, setErrorMSG] = useState("");

  // --------------------------------------
  // hook

  useEffect(() => {
    setErrorMSG("")
  },[loginEmail])

  // --------------------------------------
  // f

  const btn_forget_password = async (e) => {
    e.preventDefault();

    try {
      if (loginEmail.length < 5 || loginEmail.length > 254) {
        setErrorMSG("E-mail musí mít mezi 5 a 254 znaky.");
        return;
      }

      const response = await route_universal(
        "PATCH",
        urlServer,
        "/forget_password",
        "email",
        loginEmail
      );

      setErrorMSG(response.error ? response.message : response.msg);

    } catch (error) {
      setErrorMSG(
        "Chyba fronendu při odesílíní: " +
          error +
          ". Kontaktujte nás Váš DMAPS.",
      );
    }
  };

  // ---------------------------------------

  return (
    <div className="Login_ForgetPassword">
      

      <div className="title">
        <h1>Obnovení hesla</h1>

        {/* Btn back */}
        <BtnBack onClick={btnBack} />
      </div>

      <div className="input_box">
        <InputLineText
          type="email"
          placeholder="E-mail"
          value={loginEmail}
          set={setLoginEmail}
          regexSetting="email"
          autoComplete="email"
        />
      </div>

      <div className="error_box">
        <p style={{color: errorMSG === "E-mail s dočasným 1 hodinovým odkazem na změnu hesla odeslán" ? "green" : "red"}}>{errorMSG}</p>
      </div>

      <div className="button_box">
        <button type="submit" onClick={btn_forget_password}>
          Obnovit heslo
        </button>
      </div>
    </div>
  );
};

export default LoginForgetPassword;

import "./UserData_listFromLast_pageScroll.scss";
import { useState, useEffect } from "react";
import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";
import UserDataListFromLastLimitResponse from "./UserData_listFromLast_limitResponse/UserData_listFromLast_limitResponse";
import PageSelect from "./Page_Select/Page_Select";

const UserData_listFromLast_pageScroll = ({ fetch_result, page, setPage, limitResponse, setLimitRespose }) => {

  const [frist_visible_pages, setFrist_visible_pages] = useState(0);

  // ----------------------------
  // useEffect(() => {
  //   // frist 6
  //   if(page <= 6) {
  //     if(frist_visible_pages === 0) {
  //       return;
  //     } else {
  //       setFrist_visible_pages(0)
  //     }
  //   }
  //   // last 6 
  //   // else if ((fetch_result.totalPages - page) <= 5) {
  //   //   return;
  //   // } 
  //   // 
  //   else if (page > fetch_result.totalPages - 6) {
  //     if (frist_visible_pages !== fetch_result.totalPages - 11) {
  //       setFrist_visible_pages(fetch_result.totalPages - 11);
  //     }
  //     return;
  //   } else {
  //     setFrist_visible_pages(page - 6)
  //   }
    

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[page])

  useEffect(() => {
    // Prvních 6 stránek
    if (page <= 4) {
      if (frist_visible_pages !== 0) {
        setFrist_visible_pages(0);
      }
      return;
    }
  
    // Posledních 6 stránek
    if (page > fetch_result.totalPages - 3) {
      if (frist_visible_pages !== fetch_result.totalPages - 7) {
        setFrist_visible_pages(fetch_result.totalPages - 7);
      }
      return;
    }
  
    // Jinak nastavit frist_visible_pages dynamicky
    setFrist_visible_pages(page - 4);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, fetch_result.totalPages]);


  // ----------------------------

  return (
    <div className="UserData_listFromLast_pageScroll">
      
      {/* number lists show */}
      <UserDataListFromLastLimitResponse limitResponse={limitResponse} setLimitRespose={setLimitRespose} />

      {/* Pages */}
      <section className="pageSelect">

        {/* Back */}
        {page > 1 ? 
          (
            <button className="arrow" onClick={() => setPage(page - 1)}>
              <VscChevronLeft />
            </button>
          ) : (
            <div className="white-space"></div>
          )
      }

        {/* Pages */}
        {fetch_result.totalPages && (
          <section className="pages">
            {(() => {
              const buttons = [];
              for (let i = frist_visible_pages; i < (fetch_result.totalPages < 7 ? fetch_result.totalPages : frist_visible_pages + 7); i++) {
                buttons.push(
                  <button
                    key={i + 1}
                    className={page === i + 1 ? "activePage" : ""}
                    onClick={() => setPage(i + 1)}
                  >
                    {i + 1}
                  </button>
                );
              }
              return buttons;
            })()}
          </section>
        )}

        {/* Next */}
        {page !== fetch_result.totalPages ? (
            <button className="arrow" onClick={() => setPage(page + 1)}>
              <VscChevronRight />
            </button>
          ) : (
            <div className="white-space"></div>
          )
        }
      </section>

      {/* List select */}
      <PageSelect page={page} setPage={setPage} totalPages={fetch_result.totalPages} />
    </div>
  );
};

export default UserData_listFromLast_pageScroll;
import React from 'react'
import "./Btn_Back.scss";
import { IoMdArrowRoundBack } from "react-icons/io";

const BtnBack = ({onClick}) => {
  return (
    <div className="Btn_Back">
        <button 
            title="Zpět" 
            type="button"
            onClick={onClick}
        ><IoMdArrowRoundBack /></button>
    </div>
  )
}

export default BtnBack
import { useContext } from "react";
import { GlobalContext } from "../../../../global/GlobalContext";
import "./NewRespondent_NewEmail_Send.scss";
import route_universal from "../../../../routes/global/route_universal";
import { RiMailSendLine } from "react-icons/ri";

const NewRespondentNewEmailSend = ({ newResponse_ID }) => {
  const { urlServer, setError } = useContext(GlobalContext);

  // ---------------------
  // f
  const f_emailSend = async () => {
    try {
      const response = await route_universal(
        "POST",
        urlServer,
        "/newRespondent_newEmailSend",
        "newResponse_ID",
        newResponse_ID
      );

      console.log(response);

      setError(response.error ? response.message : response.msg);
    } catch (err) {
      setError(`FrontEnd chyba: ${err.message}. Kontaktujte nás. Váš Novitim.`);
    }
  };
  // ---------------------
  return (
    <div className="NewRespondent_NewEmail_Send">
      <button onClick={f_emailSend} title="Znovu odeslat e-mail">
        <RiMailSendLine />
      </button>
    </div>
  );
};

export default NewRespondentNewEmailSend;

const rank_data = [
    {
      value: 0,
      label: "Hlavní programátor"
    }, 
    {
      value: 1,
      label: "Programátor"
    },
    {
      value: 2,
      label: "Datový zadavatel"
    },
    {
      value: 3,
      label: "Božský vedoucí"
    },
    {
      value: 4,
      label: "Personalista Novitim"
    },
    {
      value: 7,
      label: "Konzultant"
    }
  ];

export default rank_data;
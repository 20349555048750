import { useState, useContext } from "react";
import { GlobalContext } from "../../../../../../../global/GlobalContext";
import "./NewCompany.scss";
import InputContainer from "../../../../../../elements/inputs/InputContainer/InputContainer";
import route_post_universal from "../../../../../../../routes/global/route_post_universal";

const NewCompany = ({ setPhase, setErrorMsg }) => {
  const { 
    urlServer, 
    setError, 
    // autoShow
    setAutoShow_company,
    admin_color,
    admin_name,
    admin_surname,
  } = useContext(GlobalContext);

  const [companyName, setCompanyName] = useState("");
  const [companyURL, setCompanyURL] = useState("");
  const [CEOShow, setCEOShow] = useState(false);
  const [main_processor_Show, setMain_processor_Show] = useState(false);
  const [CEO_Name, setCEO_Name] = useState("");
  const [CEO_Surname, setCEO_Surname] = useState("");
  const [CEO_Phone, setCEO_Phone] = useState("");
  const [CEO_Email, setCEO_Email] = useState("");
  const [main_processor_Name, setMain_processor_Name] = useState("");
  const [main_processor_Surname, setMain_processor_Surname] = useState("");
  const [main_processor_Phone, setMain_processor_Phone] = useState("");
  const [main_processor_Email, setMain_processor_Email] = useState("");
  // ----------------------------------------

  const btnCreateCompany = async () => {
    if (
      companyName &&
      companyURL &&
      ((CEO_Name && CEO_Surname && CEO_Phone && CEO_Email) ||
        (main_processor_Name &&
          main_processor_Surname &&
          main_processor_Email &&
          main_processor_Phone))
    ) {
      try {
        const response = await route_post_universal(
          urlServer,
          "/company_create",
          "company",
          companyName,
          "company_URL",
          companyURL,
          "main_processor_Name",
          main_processor_Name,
          "main_processor_Surname",
          main_processor_Surname,
          "main_processor_Email",
          main_processor_Email,
          "main_processor_Phone",
          main_processor_Phone,
          "CEO_Name",
          CEO_Name,
          "CEO_Surname",
          CEO_Surname,
          "CEO_Email",
          CEO_Email,
          "CEO_Phone",
          CEO_Phone
        );

        // Pokud je odpověď úspěšná
        if (response.success) {
          setPhase(0);
          // - autoShow new Company
          setAutoShow_company({
            admin: {
              color: admin_color,
              name: admin_name,
              surname: admin_surname,
              // _id: "",
            },
            // admin_create_ID: "",
            coins: 0,
            company: companyName,
            tagCount: 0,
            _id: response.company_ID
          })
        }

        setError(response.msg)

        // // Pokud se jedná o chybu od backendu (např. duplicita společnosti)
        // else if (response && response.msg) {
        //   setErrorMsg(response.msg); // Odeslání správné chybové zprávy
        // }
        // // Neznámá chyba
        // else {
        //   setErrorMsg(response.msg);
        // }
      } catch (err) {
        // Chyba na straně klienta nebo nedosažitelný backend
        setErrorMsg("Client - Catch: " + err.message);
      }
    } else {
      setErrorMsg("Vyplňte Společnost nebo Kontaktní osobu");
    }
  };

  const show_CEO = () => {
    setCEOShow(!CEOShow)

    if(main_processor_Show) {
      setMain_processor_Show(false)
    }
  }

  const show_Main_processor = () => {
    setMain_processor_Show(!main_processor_Show)

    if(CEOShow) {
      setCEOShow(false)
    }
  }
  // -----------------------------------------

  return (
    <section className="NewRespondent NewCompany">
      <div className="NewRespondent_title">
        <h1>Vytvořit společnost</h1>
      </div>
      <div className="NewRespondent_main NewCompany_main">

        <div className="company_info">
          <InputContainer
            type="text"
            label="Jméno společnosti"
            id="companyName"
            value={companyName}
            set={setCompanyName}
            regexSetting="tag"
            autoComplete="off"
          />

          <InputContainer
            type="text"
            label="URL společnosti"
            id="companyURL"
            value={companyURL}
            set={setCompanyURL}
            regexSetting="URL"
            autoComplete="off"
          />
        </div>
        

        <div className="btn_add">
          <button className="normalButton" onClick={show_CEO}>
            Přidat CEO
          </button>
          <button className="normalButton" onClick={show_Main_processor}>
            Přidat kontaktní osobu
          </button>
        </div>

        {/* CEO SHOW */}
        {CEOShow && (
          <div className="CEO">
            <InputContainer
              type="text"
              label="CEO - Jméno"
              id="CEO_Name"
              value={CEO_Name}
              set={setCEO_Name}
              regexSetting="name"
              autoComplete="off"
            />
            <InputContainer
              type="text"
              label="CEO - Přijmení"
              id="CEO_Surname"
              value={CEO_Surname}
              set={setCEO_Surname}
              regexSetting="name"
              autoComplete="off"
            />
            <InputContainer
              type="text"
              label="CEO - E-mail"
              id="CEO_Email"
              value={CEO_Email}
              set={setCEO_Email}
              regexSetting="email"
              autoComplete="off"
            />

            {/* Phone */}
            <InputContainer
              type="tel"
              label="CEO - Telefon (včetně předvolby)"
              id="CEO_Phone"
              value={CEO_Phone}
              set={setCEO_Phone}
              regexSetting="phone"
              autoComplete="off"
            />
          </div>
        )}

        {/* main_processor - show */}
        {main_processor_Show && (
          <div className="main_processor">
            <InputContainer
              type="text"
              label="Kontaktní osoba - Jméno"
              id="main_processor_Name"
              value={main_processor_Name}
              set={setMain_processor_Name}
              regexSetting="name"
              autoComplete="off"
            />
            <InputContainer
              type="text"
              label="Kontaktní osoba - Přijmení"
              id="main_processor_Surname"
              value={main_processor_Surname}
              set={setMain_processor_Surname}
              regexSetting="name"
              autoComplete="off"
            />
            
            <InputContainer
              type="text"
              label="Kontaktní osoba - E-mail"
              id="main_processor_Email"
              value={main_processor_Email}
              set={setMain_processor_Email}
              regexSetting="email"
              autoComplete="off"
            />

            {/* Phone */}
            <InputContainer
              type="tel"
              label="Kontaktní osoba - Telefon (včetně předvolby)"
              id="main_processor_Phone"
              value={main_processor_Phone}
              set={setMain_processor_Phone}
              regexSetting="phone"
              autoComplete="off"
            />
          </div>
        )}

        
        <div className="btn_create"> 
          <button className="normalButton" onClick={btnCreateCompany}>
            Vytvořit společnost
          </button>
        </div>
        
      </div>
    </section>
  );
};

export default NewCompany;

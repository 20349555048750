import { useState } from "react"
import "./BtnNewRespondent.scss"
import PopUpNewRespondent from './PopUpNewRespondent/PopUpNewRespondent'

const BtnNewRespondent = () => {

  const [newRespondent, setNewRespondent] = useState(false)
  
  const btn_BtnNewRespondent = () => {
    setNewRespondent(true)
  }

  return (
    <div className="BtnNewRespondent">
        <button className="btnNewRespondent" onClick={btn_BtnNewRespondent}>Nové zadání diagnostiky</button>
        {
          newRespondent && <PopUpNewRespondent setNewRespondent={setNewRespondent}/>
        }
    </div>
  )
}

export default BtnNewRespondent
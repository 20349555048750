import { useEffect, useState, useContext } from "react";
import "./Consultants_New.scss";
import { GlobalContext } from "../../../../../../global/GlobalContext";
import Select from "../../../../../elements/selects/SelectMore/SelectMoreWithNull/SelectMoreWithNull"
import route_universal from '../../../../../../routes/global/route_universal';
import { IoMdPersonAdd } from "react-icons/io";

const ConsultantsNew = ({ 
  setShow_addConsultant,
  setCompanyConsulants_list,
  // from OneCompany
  company_ID,
 }) => {
  const { 
    urlServer, 
    setError, 
    setLoading_user,
    // auto set admin data
    admin_name,
    admin_surname,
    admin_phone,
    admin_email,
    admin_company_Name,
  } = useContext(GlobalContext);

  const [select_all_consultants, setSelect_all_consultants] = useState(null);
  const [selected_companyConsultation, setSelected_companyConsultation] = useState(null)

  console.log(selected_companyConsultation);

  // -----------------
  // Hook
  
  // find consultant for respondent
  useEffect(() => {
    const f_fetchData = async () => {

      setLoading_user(true);
      
      try {
        const response = await route_universal(
          "POST",
          urlServer,
          "/find_all_colstunant_select",
        )

        if(response.success) {
          setSelect_all_consultants(response.fetch_data); // fetch     
        } else {
          setError(response.error ? response.message : response.msg);
          setSelect_all_consultants([]);
        }

      } catch (err) {
        setSelect_all_consultants([]);
        setError("Chyba frontEndu: " + err + ". Kontaktujte nás. Váš DMAPS.")
      }  finally {
        setLoading_user(false);
      }
    }

    f_fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ------------------------------------
  // f

  // btn add Company Consultant to list
  const btn_add_companyCosnultant = async () => {
    try {
      const response = await route_universal(
        "PATCH",
        urlServer,
        "/add_Consultant_forCompany",
        "companyConsultant_ID",
        selected_companyConsultation,
        "for_company_ID",
        company_ID
      )
  
      if(response.success) {
        setShow_addConsultant(false);
        // auto set to list
        setCompanyConsulants_list((prev) => [
          ...prev,  // Zachová existující položky v poli
          {
            consultant: response.new_admin, 
            admin_add_consultant: {
              admin_name,
              admin_surname,
              admin_phone,
              admin_email,
            },
            admin_companyAdd_consultant: {
              company: admin_company_Name
            }
          } // Přidá nový objekt na konec
        ]);
      }

      setError(response.error ? response.message : response.msg);
    } catch (err) {
      setError("Chyba frontEndu: " + err + ". Kontaktujte nás. Váš DMAPS.")
    } 
  }

  // -------------------------------------

  return (
    <div className="Consultants_New">
        <h2>Přidání konzultanta</h2>
        {/* select */}
        {
          select_all_consultants?.length > 0 
          && <Select 
            object={select_all_consultants} 
            onChange={setSelected_companyConsultation} 
            placeholder="Vyberte konzultanta"
            menuPlacement="bottom"
          />
        }

        {/* btn add */}
        {
          selected_companyConsultation && <button className="button_blue" onClick={btn_add_companyCosnultant}>
            <p>Přidat</p>
            <IoMdPersonAdd />
          </button>
        }
    </div>
  )
}

export default ConsultantsNew
import { useState } from "react";
import { ImEye, ImEyeBlocked } from "react-icons/im";
import "./InputLine_Password_WithLabel.scss";
import InputLineTextWithLabel from "../../InputLine_Text/InputLine_Text_WithLabel/InputLine_Text_WithLabel";

const InputLinePasswordWithLabel = ({
  label,
  id,
  value,
  set,
  regexSetting,
  // autoComplete,
  // readOnly,
}) => {
    const [type, setType] = useState("password");

    const btnPassword = (e) => {
        e.preventDefault();
    
        setType((prevState) => (prevState === "password" ? "text" : "password"));
      };

  return (
    <div className="InputLine_Password_WithLabel">
      <InputLineTextWithLabel
        type={type}
        label={label}
        id={id}
        value={value}
        set={set}
        regexSetting={regexSetting}
        autoComplete="off"
      />
      
      <button className="password" onClick={btnPassword} type="button">
        {type === "password" ? <ImEye /> : <ImEyeBlocked />}
      </button>
    </div>
  );
};

export default InputLinePasswordWithLabel;

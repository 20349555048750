import { useState, useContext } from "react";
import "./UserData_Result_NumberColorSets.scss";
import { GlobalContext } from "../../../../../global/GlobalContext";
import { f_date_to_cz } from "../../../../../utils/date/f_date_to_cz";
import { f_date_only } from "../../../../../utils/date/f_date_only";
import route_post_universal from "../../../../../routes/global/route_post_universal";
import BtnShowColorSetPopUpColorSet from "../../UserData_listFromLast/UserData_listFromLast_result/UserData_listFormLast_result_btnShowColorSet/BtnShowColorSet_PopUpColorSet/BtnShowColorSet_PopUpColorSet";
import CheckBoxsOnlyShow from "../../../../elements/checkBox/CheckBoxs_onlyShow/CheckBoxs_onlyShow";
import UserDataListFromLastPageScroll from "../../../../../components/completeComponents/UserData_listFrom_last_result/UserData_listFromLast_pageScroll/UserData_listFromLast_pageScroll";

const UserData_Result_NumberColorSets = ({
  name,
  fetch_colorSet,
  setFetch_result,
  limitResponse,
  setLimitRespose,
  page,
  setPage,
  // delet one User
  setFetch_result_list,
  fetch_result,
  URLValue, 
  setURLValue, 
  userName, 
  setUserName
}) => {
  const { urlServer, setLoading_user } = useContext(GlobalContext);
  console.log(fetch_colorSet);

  const [show, setShow] = useState(false);
  const [fetch_data, setFetch_data] = useState({});

  const f_oneColorSet = async (colroSet_ID) => {
    setLoading_user(true)
    setShow(true);
    console.log(colroSet_ID);

    try {
      let result = await route_post_universal(
        urlServer,
        "/findOneColorSet",
        "colorSet_ID",
        colroSet_ID
      );

      if (result.success) {
        console.log("prošlo")
        console.log(result.result);
        setFetch_data(result.result);
        result = "";
      }
    } catch (err) {
      console.log("Front End Error: " + err);
    } finally {
      setLoading_user(false)
    }
  };

  return (
    <div className="UserData_Result_NumberColorSets">
      <div className="title2">
        <h2>Vyplněné diagnostiky uživatele: </h2>
        <h2 className="number">{fetch_colorSet?.fetch_data?.length}</h2>
      </div>

      <section className="allUserColorSet">
        <div className="titles">
          <p className="number_colorSet">Číslo diagnostiky</p>
          <div className="company">
          <p>Společnost</p>
          </div>
          
          <p className="date_create">Datum Vytvoření</p>
          <p className="actual_state">Aktuální stav</p>
        </div>

        {fetch_colorSet?.fetch_data &&
          fetch_colorSet?.fetch_data.map((oneColorSetInfo, index) => {
            const {
              _id,
              /*code, admin,*/ company,
              ready_for_consultation,
              consultation_complete,
              feedback_from_respondent,
              date_complete,
              date_create /*tag, wordSet*/,
            } = oneColorSetInfo;
            const { /*company_ID, */ company_Name } = company;
            // const {admin_company_ID, admin_create_ID} = admin;
            // const {tag_ID, tag_Name} = tag;
            // const {wordSet_ID, wordSet_name} = wordSet;

            return (
              <div
                key={_id}
                className="oneColorSet"
                onClick={() => f_oneColorSet(_id)}
              >
                <p className="number_colorSet">{index + 1}</p>
                
                <p className="company">{company_Name}</p>
                <p className="date_create" 
                  title={`Datum Vytvoření: ${f_date_to_cz(date_create)}`}
                >
                  {f_date_only(date_create)}
                </p>
                
                <div className="actual_state">
                  {
                    (ready_for_consultation || consultation_complete || feedback_from_respondent) 
                    ?
                    <CheckBoxsOnlyShow
                      ready_for_consultation={ready_for_consultation}
                      consultation_complete={consultation_complete}
                      feedback_from_respondent={feedback_from_respondent}
                    />
                    : <p title={`Datum dokončení: ${f_date_to_cz(date_complete)}`}>{f_date_only(date_complete)}</p>
                  }
                </div>
                
              </div>
            );
          })}
      </section>

      {/* Page Scroller */}
      <section className="pageScroll">
        {fetch_colorSet.totalPages && fetch_colorSet.totalPages !== 1 && (
          <UserDataListFromLastPageScroll
            fetch_result={fetch_colorSet}
            page={page}
            setPage={setPage}
            limitResponse={limitResponse}
            setLimitRespose={setLimitRespose}
          />
        )}
      </section>

      {/* POP UP COLOR SET */}
      {show && fetch_data._id && (
        <BtnShowColorSetPopUpColorSet
          setShow={setShow}
          fetch_data={fetch_data}
          setFetch_data={setFetch_data}
          name={name}
          setFetch_result={setFetch_result}
          setFetch_result_list={setFetch_result_list}
          fetch_result={fetch_result}
          URLValue={URLValue}
          setURLValue={setURLValue}
          userName={userName}
          setUserName={setUserName}
        />
      )}
    </div>
  );
};

export default UserData_Result_NumberColorSets;

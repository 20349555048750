import Select from "react-select";

const SelectMore = ({options, object, onChange, defaultOption, placeholder, menuPlacement}) => {
    let formattedOptions;

    if (!object || object.length === 0) {
        formattedOptions = options && options.length > 0 ? options.map((text, index) => ({
          value: index,
          label: text,
        })) : [];
      } else {
        formattedOptions = object.map((oneObject, index) => {
          const { value, label } = oneObject;
    
          return {
            value,
            label,
            index,
          };
        });
      }

  return (
    <>
        <Select
        options={formattedOptions}
        onChange={(selectedOption) => {
          // Pokud je možnost vymazána, nastavte hodnotu zpět na null
          onChange(selectedOption || {});
        }}
        menuPlacement={menuPlacement || "auto"}
        placeholder={placeholder}
        isClearable={true}
        maxMenuHeight={200} // px
        // isSearchable={false} // wraiting
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: "center",
          }),
          option: (provided) => ({
            ...provided,
            textAlign: "center",
            cursor: "pointer",
          }),
        }}
        defaultValue={defaultOption} // Set the default option
      />
    </>
  )
}

export default SelectMore
import { useEffect, useState } from "react";
import "../NewRespondent.scss";
import "./RespondentNew0.scss";
import InputContainer from "../../../../../elements/inputs/InputContainer/InputContainer";
import SelectWordSet from "../RespondentNew1/NewTag/SelectWordSet/SelectWordSet";
import RadioGender from "../../../../../elements/radio/Radio_Gender/Radio_Gender";
import RadioUniversalColumn from "../../../../../elements/radio/Radio_Universal_Column/Radio_Universal_Column";
import SelectTagFindAllName from "../../../../../elements/selects/Specific_With_Function/Select_Tag_FindAllName/Select_Tag_FindAllName";

const RespondentNew0 = ({
  tagSelect,
  setNewTagShow,
  setTagSelect,
  setSelected_TagData,
  // setError,
  // error,
  // tag's default WordSet
  respondent_wordSet_select,
  setRespondent_wordSet_select,
  // gender + Name + email
  gender,
  setGender,
  name,
  setName,
  surname,
  setSurname,
  addressing,
  setAddressing,
  email,
  setEmail,
  // email form
  emailForm,
  setEmailForm,
  // tag_profession
  tag_profesion_Name,
  setTag_profesion_Name,
  tag_profesion_URL,
  setTag_profesion_URL,
}) => {
  const [fetch_data, setFetch_data] = useState([]); // tags options

  // console.log(fetch_data)

  const radio_options = [
    { label: "1 - Diagnostika ", 
      value: "0", 
      color: "#00d9ff" },
    {
      label: "2 - Diagnostika - se jménem společnosti",
      value: "1",
      color: "#ff4081",
    },
    {
      label:"3 - Diagnostika - se jménem společnosti a pozicí",
      value: "2",
      color: "#00ff2a",
    },
    {
      label:"4 - Diagnostika - s pozicí",
      value: "3",
      color: "#cc00ff",
    },
    { 
      label: "5 - Diagnostika - štika", 
      value: "4" 
    },
  ];
  // -----------------------------

  // set tag's default wordSet + company
  useEffect(() => {
    if (tagSelect.label) {
      // console.log(fetch_data[tagSelect.index].default_wordSet)
      setRespondent_wordSet_select(fetch_data[tagSelect.index].default_wordSet);
      setSelected_TagData({
        company: fetch_data[tagSelect.index].company,
        // job_position: fetch_data[tagSelect.index].job_position,
        // job_URL: fetch_data[tagSelect.index].job_URL,
      });
      setTag_profesion_Name(fetch_data[tagSelect.index].job_position)
      setTag_profesion_URL(fetch_data[tagSelect.index].job_URL)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagSelect]);

  return (
    <div className="NewRespondent">
      <>
        <div className="NewRespondent_title">
          <h1>Nová diagnostika</h1>
        </div>

        <div className="NewRespondent_main">
          <SelectTagFindAllName  
            setTagSelect={setTagSelect}  
            // for RespondentNew0
            setFetch_data_FullData={setFetch_data}
          />
          
          {/* Create new Tag */}
          {!tagSelect.label && (<>
            <div className="or"></div>
            
            <button
              className="normalButton"
              onClick={() => setNewTagShow(true)}
            >
              Vytvořit nový tag
            </button>
          </>
          )}

          {/* Respondent */}
          {tagSelect.label && (
            <div className="RespondentNew0_respondent_box">
              <h2>Respondent</h2>
              <div className="gender_box">
                <RadioGender gender={gender} setGender={setGender} />
              </div>
              

              {/* WordSet */}
              <SelectWordSet
                wordSet_select={respondent_wordSet_select}
                setWordSet_select={setRespondent_wordSet_select}
              />

              <InputContainer
                type="text"
                label="Jméno"
                id="name"
                value={name}
                set={setName}
                regexSetting="name"
              />

              <InputContainer
                type="text"
                label="Přijmení"
                id="surname"
                value={surname}
                set={setSurname}
                regexSetting="name"
              />

              <InputContainer
                type="text"
                label="Oslovení pro emailing *5. pádem"
                id="addressing"
                value={addressing}
                set={setAddressing}
                regexSetting="name"
              />

              <InputContainer
                type="text"
                label="E-mail"
                id="email"
                value={email}
                set={setEmail}
                regexSetting="email"
              />

              {(emailForm === "2" || emailForm === "3") && (
                <div className="tag_profession">
                  <InputContainer
                    type="text"
                    label="Jméno pozice tagu"
                    id="tag_profession_Name"
                    value={tag_profesion_Name}
                    set={setTag_profesion_Name}
                    regexSetting="tag"
                  />
                  <InputContainer
                    type="text"
                    label="URL pozice tagu"
                    id="tag_profession_URL"
                    value={tag_profesion_URL}
                    set={setTag_profesion_URL}
                    regexSetting="URL"
                  />
                </div>
              )}

              {/* RADIO - Select e-mail form  */}
              <div className="email_form">
                <h2 className="email_form_title">Způsob odeslaní e-mailu</h2>
                <RadioUniversalColumn
                  options={radio_options}
                  selectedValue={emailForm}
                  setSelectedValue={setEmailForm}
                /> 
              </div>
              
                        
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default RespondentNew0;

import React, { useState, useContext } from "react";
import { GlobalContext } from "../../../../../../../global/GlobalContext";
import "./Form_FeedBack_Consultant.scss";
import route_universal from "../../../../../../../routes/global/route_universal";
import { f_updateConsultationStatus } from "../../../../../../../utils/checkBox/colorSets/f_updateConsultationStatus";
import { FaWpforms } from "react-icons/fa6";
import { LuCopy } from "react-icons/lu";
import SelectMUIDate from "../../../../../../elements/selects/Specific_With_Function/Select_MUI_Date/Select_MUI_Date";

const FormFeedBackConsultant = ({
  colorSet_ID,
  setShow_consultationForm,
  setConsultation,
  consultation,
  setFetch_result,
}) => {
  const { urlServer, setError, setLoading_user } = useContext(GlobalContext);

  const [isHovered_ID, setIsHovered_ID] = useState(false); // Stav pro hover

  const [selectedDate, setSelectedDate] = useState(null); // date select

  const f_copyText = async () => {
    try {
      await navigator.clipboard.writeText(colorSet_ID);
      setError("ID diagnostiky zkopírováno");
    } catch (err) {
      setError("Error fronend: " + err);
    }
  };

  const btn = async () => {
    // setSend_consultationForm(true)
    setLoading_user(true)

    try {
      if(selectedDate) {
        // Připravit nový stav, který bude opakem aktuálního
        const newValue = !consultation;

        // Odeslání PATCH požadavku pomocí route_universal
        const response = await route_universal(
          "PATCH",
          urlServer,
          "/update_checkBox_consultation",
          "colorSet_ID",
          colorSet_ID,
          "consultation_complete",
          newValue,
          "date",
          selectedDate
        );

        // Kontrola odpovědi ze serveru
        if (response.success) {
          console.log("Úspěšně aktualizováno:", response.updatedColorSet);

          // Aktualizace stavu checkboxů na základě odpovědi serveru
          setConsultation(response.updatedColorSet.consultation_complete);

          // Aktualizace fetch_result
          f_updateConsultationStatus(setFetch_result, colorSet_ID, {
            consultation_complete: response.updatedColorSet.consultation_complete,
          });
        }

        setError(response.error ? response.message : response.msg);

        setShow_consultationForm(false);

      } else {
        setError("Je třeba vyplnit také datum")
      }

    } catch (err) {
      setError("Chyba při volání checkBox_consultation:", err.message);
    } finally {
      setLoading_user(false)
    }
  };

  return (
    <div className="Form_FeedBack_Consultant">
      <div className="box">
        <p className="number">1.</p>
        <div className="code_box">
          <p className="code_title">ID diagnostiky</p>
          <p
            className="code_data"
            onClick={f_copyText}
            onMouseEnter={() => setIsHovered_ID(true)} // Nastaví hover stav
            onMouseLeave={() => setIsHovered_ID(false)} // Odstraní hover stav
          >
            {colorSet_ID}{" "}
            <LuCopy
              className="copy-icon"
              size={20}
              style={{
                color: isHovered_ID ? "#f25e0d" : "#a8a8a8", // Barva ikony při hoveru
                transition: "color 0.3s ease", // Plynulý přechod barvy
              }}
            />
          </p>
        </div>
        <div></div>
      </div>

      <div className="link_box box">
        <p className="number">2.</p>
        <div className="code_box">
          <p className="code_title">Odkaz na formulář</p>
          <a
            className="link"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeixc9FQIrBUgqULxrKLNjBdmoYpEO5vkdhU8tdVrVM82rEBw/viewform"
            target="_blank"
            rel="noreferrer"
            title="Odkaz na formulář pro feedBack konzultanta"
          >
            <FaWpforms />
          </a>
        </div>
        <div></div>
      </div>

      <div className="box">
        <p className="number">3.</p>
        <div className="date">
          <SelectMUIDate selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        </div>
      </div>

      <div className="btn_box">
        <p className="number">4. </p>
        <button onClick={btn}>Formulář vyplněn</button>
        <div></div>
      </div>
    </div>
  );
};

export default FormFeedBackConsultant;

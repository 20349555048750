import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../../../../global/GlobalContext";
import route_universal from "../../../../../routes/global/route_universal";
import InputLineTextWithLabel from "../../../../elements/inputs/InputLine/InputLine_Text/InputLine_Text_WithLabel/InputLine_Text_WithLabel";
import InputLinePasswordWithLabel from "../../../../elements/inputs/InputLine/InputLine_Password/InputLine_Password_WithLabel/InputLine_Password_WithLabel";
import RadioGender from "../../../../elements/radio/Radio_Gender/Radio_Gender";
import InputColorChromePicker from "../../../../elements/inputs/InputColor/InputColor_ChromePicker";

const CeoRegistrationForm = ({ fetch_data, setFront_Error }) => {
  const { urlServer } = useContext(GlobalContext);

  const [name, setName] = useState(fetch_data.admin.admin_data.name);
  const [surname, setSurname] = useState(fetch_data.admin.admin_data.surname);
  const [nickName, setNickName] = useState("");
  const [email, setEmail] = useState(fetch_data.admin.admin_data.email);
  const [phone, setPhone] = useState(fetch_data.admin.admin_data.phone);
  const [gender, setGender] = useState(null);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  // color
  const [showColor, setShowColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");

  useEffect(() => {
    setFront_Error("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, surname, nickName, email, phone, gender, password, password2]);

  const btn_submit = async (e) => {
    e.preventDefault();

    try {
      if (
        !name ||
        !surname ||
        !email ||
        !phone ||
        !gender ||
        !selectedColor ||
        !password ||
        !password2 ||
        !fetch_data.admin.admin_data.code ||
        !fetch_data._id
      ) {
        return setFront_Error("Vyplňte všechna povinná pole");
      }

      if (password !== password2) {
        return setFront_Error("Hesla se musí schodovat");
      }

      if (password.length < 8 || password.length > 64) {
        return setFront_Error("Délka hesla musí být mezi 8 - 64 znaky");
      }

      if (gender !== "0" && gender !== "1") {
        return setFront_Error("Špatný formát pohlaví. Kontaktujte nás. Váš DMAPS");
      }

      if (!/^[0-9a-fA-F]{24}$/.test(fetch_data._id)) {
        setFront_Error("Neplatné ID společnosti");
      }

      if(!/^#[a-fA-F0-9]{6}$/.test(selectedColor)) {
        setFront_Error("Špatný formát barvy HEX")
      }

      const response = await route_universal(
        "POST",
        urlServer,
        "/ceo_registration_form",
        "name",
        name,
        "surname",
        surname,
        "nickName",
        nickName,
        "email",
        email,
        "phone",
        phone,
        "gender",
        gender,
        "color",
        selectedColor,
        "password",
        password,
        "code",
        fetch_data.admin.admin_data.code,
        "company_ID",
        fetch_data._id
      );

      console.log(response?.err?.code)
      console.log("Error keyValue:", response?.err?.keyValue);

      if(response?.err?.code === 11000) {
        if (response.err.keyValue?.email === email) {
            return setFront_Error("Uživatel s tímto e-mailem již existuje");
        } else if (response.err.keyValue?.phone === phone) {
           return setFront_Error("Telefon již existuje");
        } 
      }

      if (response?.success) {
        window.location.href = '/';
      } else {
        setFront_Error(response.error ? response.message : response.msg);
      }
      
    } catch (err) {
      setFront_Error("Chyba Frontendu " + err + " kontaktujte nás. Váš DMAPS.");
    }
  };

  return (
    <div className="Ceo_Registration_Form">
      <p className="company">{fetch_data.company}</p>
      <p className="admin_status">
        {fetch_data.admin.admin_status === "CEO" ? "CEO" : "Firemní zástupce"}
      </p>

      <InputLineTextWithLabel
        type="text"
        label="Jméno"
        id="name"
        value={name}
        set={setName}
        regexSetting="name"
      />
      <InputLineTextWithLabel
        type="text"
        label="Přijmení"
        id="surname"
        value={surname}
        set={setSurname}
        regexSetting="name"
      />
      <InputLineTextWithLabel
        type="text"
        label="Oslovení (nepovinné)"
        id="nickName"
        value={nickName}
        set={setNickName}
        regexSetting="universal"
      />
      <InputLineTextWithLabel
        type="text"
        label="E-mail"
        id="email"
        value={email}
        set={setEmail}
        regexSetting="email"
      />
      <InputLineTextWithLabel
        type="text"
        label="Telefon (včetně předvolby)"
        id="phone"
        value={phone}
        set={setPhone}
        regexSetting="phone"
      />
      <RadioGender gender={gender} setGender={setGender} />

        {/* Color */}
      <div
        className="colorSelect_box"
        style={{ backgroundColor: selectedColor }}
      >
        <button
        className="btn_colorSelect"
          onClick={(e) => {
            e.preventDefault();
            setShowColor(true);
          }}
        >
          Vybrat barvu
        </button>

        {showColor && (
        <InputColorChromePicker
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          setShowColor={setShowColor}
        />
      )}
      </div> 

      <InputLinePasswordWithLabel
        label="Heslo"
        id="password"
        value={password}
        set={setPassword}
        regexSetting="password"
      />
      <InputLinePasswordWithLabel
        label="Heslo znovu"
        id="password2"
        value={password2}
        set={setPassword2}
        regexSetting="password"
      />

      <div className="btn_box">
        <button className="normalButton" type="submit" onClick={btn_submit}>
          Registrovat
        </button>
      </div>
    </div>
  );
};

export default CeoRegistrationForm;

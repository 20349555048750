import { useState, useContext } from "react";
import { GlobalContext } from "../../../../GlobalContext";
import "./Registration_window.scss";
import InputContainer from "../../../../../components/elements/inputs/InputContainer/InputContainer";
import InputPassword from "../../../../../components/elements/inputs/InputPassword/InputPassword";
import SelectBasic from "../../../../../components/elements/selects/SelectBasic/SelectBasic";
import BtnRegistration from "./BtnRegistration/BtnRegistration";
import BtnClose from "../../../../../components/elements/btn/BtnClose/BtnClose";
import InputColorChromePicker from "../../../../../components/elements/inputs/InputColor/InputColor_ChromePicker";
import rank_data from "../../../../../data/user-data/rank_data";
import RadioGende from "../../../../../components/elements/radio/Radio_Gender/Radio_Gender";
import useComponentEnterSubmit from "../../../../../hooks/form/useComponent_EnterSubmit";


const Registration_window = ({ setRegistration }) => {
  const { admin_rank } = useContext(GlobalContext);

  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [addressing_surname, setAddressing_surname] = useState("");
  const [email, setEmail] = useState("");

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [rankReg, setRankReg] = useState("");
  const [namePosition, setNamePosition] = useState("");
  const [passwordParent, setPasswordParrent] = useState("");
  // color
  const [showColor, setShowColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  // error
  const [errorMSG, setErrorMSG] = useState("");

  // -----------------------------------------
  // - Hook
  useComponentEnterSubmit(); // enter

  // -----------------------------------------
  //f
  // - filtr rank select data
  const filteredRanks = rank_data.filter(rank => rank.value > admin_rank);

  console.log(rankReg)

  // -----------------------------------------

  return (
    <div className="Registration_window">
      <section className="form">
        <BtnClose setClose={setRegistration} />
        <div className="title">
          <h1>Registrace nového uživatele</h1>
        </div>

        <div className="input_box">
          <form autoComplete="off">
            <InputContainer
              type="text"
              label="Jméno"
              id="name"
              value={name}
              set={setName}
              regexSetting="name"
            />
            <InputContainer
              type="text"
              label="Přijmení"
              id="surname"
              value={surname}
              set={setSurname}
              regexSetting="name"
            />
            <InputContainer
              type="text"
              label="Oslovení (nepovinné)"
              id="addressing_surname"
              value={addressing_surname}
              set={setAddressing_surname}
              regexSetting="universal"
            />
            <InputContainer
              type="email"
              label="E-mail"
              id="email"
              value={email}
              set={setEmail}
              regexSetting="email"
              autoComplete="off"
            />

            {/* Phone */}
            <InputContainer
              type="tel"
              label="Telefon (včetně předvolby)"
              id="phone"
              value={phone}
              set={setPhone}
              regexSetting="phone"
            />

            {/* Gender */}
            <RadioGende gender={gender} setGender={setGender} />

            {/* Password */}
            <InputPassword
              label="Heslo - nového uživatele"
              id="password"
              value={password}
              set={setPassword}
              regexSetting="password"
              autoComplete="new-password"
            />
            <InputPassword
              label="Heslo - nového uživatele znovu"
              id="password2"
              value={password2}
              set={setPassword2}
              regexSetting="password"
              autoComplete="new-password"
            />

            {/* Rank */}
            <div className="rank_select">
              <SelectBasic
                object={filteredRanks}
                onChange={setRankReg}
                placeholder="Vyberte pozici nového uživatele"
                value={rankReg}
              />
            </div>
            
            <InputContainer
              type="text"
              label="Jméno pozice"
              id="position"
              value={namePosition}
              set={setNamePosition}
              regexSetting="universal"
              autoComplete="off"
            />

            {/* Color select */}
            <div
              className="box_colorSelect"
              style={{ backgroundColor: selectedColor }}
            >
              <button className="btn_colorSelect" onClick={(e) => { e.preventDefault(); setShowColor(true); }}>
                {selectedColor ? "Změnit barvu" : "Vybrat barvu"}
              </button>

              {showColor && (
              // <InputColor
              //   setSelectedColor={setSelectedColor}
              //   setShowColor={setShowColor}
              // />
              <InputColorChromePicker 
                setSelectedColor={setSelectedColor}
                setShowColor={setShowColor}
              />
            )}
            </div>

            
          </form>
          
          {/* Parant */}
          <form autoComplete="off" className="parent_box">  
            <div className="parent"></div>

            <input
              type="text"
              name="fakeUsername"
              autoComplete="off"
              style={{
                position: 'absolute',
                top: '-9999px',
                left: '-9999px',
                height: '1px',
                width: '1px',
                opacity: 0
              }}
            />

            <InputContainer
              type="password"
              label="Heslo - Vaše"
              id="parentSecureKey"
              value={passwordParent}
              set={setPasswordParrent}
              regexSetting="password"
              autoComplete="one-time-code"
              name="secretKeyParent"
            />
          </form>
        </div>

        <div
          className="error_box"
          style={{ color: errorMSG === "Nový admin vytvořen" ? "green" : "red" }}
        >
          <p>{errorMSG}</p>
        </div>

        <div className="button_box">
          <BtnRegistration
            setErrorMSG={setErrorMSG}
            name={name}
            surname={surname}
            addressing_surname={addressing_surname}
            gender={gender}
            phone={phone}
            email={email}
            password={password}
            password2={password2}
            rankReg={rankReg}
            namePosition={namePosition}
            passwordParent={passwordParent}
            selectedColor={selectedColor}
            setRegistration={setRegistration}
          />
        </div>
      </section>
    </div>
  );
};

export default Registration_window;
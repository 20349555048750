import Select from "react-select";
import "./UserData_listFromLast_limitResponse.scss"

const UserData_listFromLast_limitResponse = ({
  limitResponse,
  setLimitRespose,
}) => {
  const options = [5, 10, 25, 50, 100].map((value) => ({
    value,
    label: value.toString(),
  }));
  const placeholder = "Počet";

  // Najdeme výchozí možnost na základě `limitResponse`
  const defaultOption = options.find((option) => option.value === limitResponse);

  return (
    <div className="UserData_listFromLast_limitResponse" title="Počet zobrazených výsledků">
      <Select
        options={options}
        onChange={(selectedOption) => setLimitRespose(selectedOption.label)}
        placeholder={placeholder}
        defaultValue={defaultOption}
        menuPlacement="auto"
        maxMenuHeight={200}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: "center",
            whiteSpace: "nowrap", // Zabrání zalamování textu
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "max-content", // Automatická šířka podle obsahu
            minWidth: "40px", // Nastaví minimální šířku
          }),
          menu: (provided) => ({
            ...provided,
            whiteSpace: "nowrap",
          }),
          option: (provided) => ({
            ...provided,
            textAlign: "center",
            cursor: "pointer",
            textOverflow: "ellipsis",
          }),
        }}
      />
    </div>
  );
};

export default UserData_listFromLast_limitResponse;
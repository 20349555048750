import React from "react";
import SelectBasic from "../../../../elements/selects/SelectBasic/SelectBasic";
import "./Page_Select.scss";

const PageSelect = ({ page, setPage, totalPages }) => {
  const pages = Array.from({ length: totalPages }, (_, i) => ({
    value: i + 1,
    label: String(i + 1),
  }));

  return (
    <div className="Page_Select" title="Vyberte list">
      <SelectBasic
        object={pages}
        onChange={setPage}
        value={page}
      />
    </div>
  );
};

export default PageSelect;

import React from "react";
import "./InputContainer.scss";
import f_input_regex from "../../../../utils/elements/regex/f_input_regex";

const InputContainer = ({
  type,
  label,
  id,
  value,
  set,
  regexSetting,
  autoComplete,
  readOnly,
  name
}) => {

  const inputChange = (value) => {
    if (f_input_regex(regexSetting, value)) {
      set(value);
    }
};

  return (
    <div className="containter-input">
      <div className="intput_box">
        <label
          htmlFor={id}
          style={
            (value || (readOnly && !value))
              ? {
                  top: "-15px",
                  left: "20px",
                  fontSize: "15px",
                }
              : {}
          }
        >
          {label}
        </label>

        <input
          type={type}
          id={id}
          onChange={(e) => inputChange(e.target.value)}
          value={value}
          autoComplete={autoComplete}
          readOnly={readOnly}
          name={name}
        />
      </div>
    </div>
  );
};

export default InputContainer;

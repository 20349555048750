import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../../../../global/GlobalContext";
import InputLineText from "../../../../elements/inputs/InputLine/InputLine_Text/InputLine_Text";
import route_universal from "../../../../../routes/global/route_universal";

const CeoRegistrationCode = ({ setFront_Error, setFetch_data }) => {
  const { urlServer } = useContext(GlobalContext);

  const [code, setCode] = useState("");

  // clear code
  useEffect(() => {
    setFront_Error("");

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  // BTN - control code
  const btn_code = async (e) => {
    e.preventDefault();

    if (!code) {
      return setFront_Error("Zadejte kód");
    }

    try {
      const response = await route_universal(
        "POST",
        urlServer,
        "/ceo_registration_code",
        "code",
        code
      );

      if (response.success) {
        setFetch_data(response.fetch_data);
        setFront_Error("");
      } else {
        setFront_Error(response.error ? response.message : response.msg);
      }

    } catch (err) {
      setFront_Error("Fronend chyba " + err  + " kontaktujte nás. Váš DMAPS.");
    }
  };

  return (
    <div className="Ceo_Registration_Code">
      <InputLineText
        type="text"
        placeholder="Zadejte kód"
        value={code}
        set={setCode}
        regexSetting="CEO_code"
        autoComplete="off"
      />

      <div className="btn_box">
        <button  className="normalButton" type="submit" onClick={btn_code}>
          Načíst kód
        </button>
      </div>
    </div>
  );
};

export default CeoRegistrationCode;

import { useState, useEffect } from "react";
import "./BtnPhaseNewRespondent.scss";
import BtnSendEmailNewRespondent from "./BtnSendEmail_NewRespondent/BtnSendEmail_NewRespondent";

const BtnPhaseNewRespondent = ({
  error,
  setError,
  setNewRespondent,
  phase,
  setPhase,
  wordSet_select,
  setTagSelect,
  //
  newTagShow,
  setNewTagShow,
  //
  tagSelect,
  gender,
  name,
  surname,
  addressing,
  email,
  respondent_wordSet_select,
  setRespondent_wordSet_select,
  selected_TagData,
  emailForm,
  // tag profession
  tag_profesion_Name,
  tag_profesion_URL
}) => {
  const [showPhase, setShowPhase] = useState(0); // setPhase for vision next, back buttons
  const [btnShow_sendEmail, setBtnShow_sendEmail] = useState(false); 

  // console.log(tagSelect.label, gender, name, surname, email, respondent_wordSet_select.label)
  // console.log(emailForm, tag_profesion_Name, tag_profesion_URL)
  // console.log(btnShow_sendEmail)
  // console.log("--------------")
  // console.log(btnShow_sendEmail, tagSelect?.label, respondent_wordSet_select?.label, name,email,emailForm)

  // Set value show next, back
  useEffect(() => {
    if(tagSelect.label && (gender === "0" || gender === "1") && name && surname && email && respondent_wordSet_select.label){
      setBtnShow_sendEmail(true)
    } else if (phase === 2){
      // TO:DO
    } else if (newTagShow) {
      setShowPhase(1);
      setPhase(1);
    } else {
      setShowPhase(phase);
      setBtnShow_sendEmail(false)
    }

    setError("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase, gender, name, surname, email, wordSet_select, tagSelect, newTagShow, respondent_wordSet_select, emailForm, tag_profesion_Name, tag_profesion_URL]);

  // ------------------------------
  // BTN
  // btn Back
  const back = () => {
    setPhase(phase - 1);
    setNewTagShow(false)

    if(phase === 1) {
      setTagSelect("")
      setRespondent_wordSet_select("")
    }
    // // Clear if is tagSelect
    // if(tagSelect && tagSelect.value){
    //   setTagSelect("");
    //   setBtnShow_sendEmail(false)
    // }
  };

  // btn Next
  const next = async () => {
    // Phase 0
    if (phase === 0) {
      /// update inputs values
      // clear whiteSpace
      const normalizeInput = (input) => {
        return input.trim().replace(/\s+/g, " ");
      };
      const normalizedName = normalizeInput(name);
      const normalizedSurname = normalizeInput(surname);
      const normalizedAddressing = normalizeInput(addressing);
      // email remove white space
      const removeEmailWhitespace = (input) => {
        return input.replace(/\s+/g, "").toLowerCase();
      };
      const normalizedEmail = removeEmailWhitespace(email);

      //Regex inputs values
      // Regex name **** ****
      // const regexName = /^\S{2,}\s\S{2,}$/;
      const regexName = /^\S{2,}(?:\s\S{2,})?$/;

      const isValidName = regexName.test(normalizedName);
      const isValidSurname = regexName.test(normalizedSurname);
      const isValidAddressing = regexName.test(normalizedAddressing)
      // Regex email
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = regexEmail.test(normalizedEmail);

      // OK
      if (isValidName && isValidEmail && tagSelect) {
        console.log(tagSelect, normalizedEmail, normalizedName)
        // setNewRespondent(false);
      } else if (!isValidName && !isValidEmail && !isValidSurname && !isValidAddressing ) {
        setError("Vyplňte všechna pole");
      } else if (!isValidName) {
        setError("Zadejte jméno");
      } else if (!isValidEmail) {
        setError("Zadejte platný formát e-mailu");
      } else if(!isValidSurname) {
        setError("Zadejte přijmení")
      } else if (gender === null) {
        setError("Vyberte pohlaví")
      }
    } 
  };

  return (
    <div className="BtnPhaseNewRespondent">
      <div className="error_box">
        <p>{error}</p>
      </div>

      <div className="buttons">
        <div className="button_box">
          {/* BTN back */}
          {phase !== 0 && (
            <button className="btn_BtnPhaseNewRespondent" onClick={back}>
              Zpět
            </button>
          )}
        </div>

        {/* Btn Send Email */}
        {btnShow_sendEmail && (
            <BtnSendEmailNewRespondent
              gender={gender}
              name={name}
              surname={surname}
              addressing={addressing}
              email={email}
              wordSet_select={respondent_wordSet_select}
              tagSelect={tagSelect}
              setNewRespondent={setNewRespondent}
              selected_TagData={selected_TagData}
              emailForm={emailForm}
              tag_profesion_Name={tag_profesion_Name}
              tag_profesion_URL={tag_profesion_URL}
            />
          )}

        <div className="button_box">
          {/* BTN next */}
          {showPhase > phase && (
            <button className="btn_BtnPhaseNewRespondent" onClick={next}>
              Další
            </button>
          )}

          
        </div>
      </div>
    </div>
  );
};

export default BtnPhaseNewRespondent;

import { useEffect, useContext } from "react";
import { GlobalContext } from "../../../global/GlobalContext";
import route_post_universal from "../../../routes/global/route_post_universal";

const useFetch_colroSet_info = (
  urlFetch,
  wordResultKey,
  state,
  wordResultKey2,
  state2,
  setFetch_result,
  search
) => {
  const { setError, urlServer, setLoading_user } = useContext(GlobalContext);

  useEffect(() => {
    const fetch_data = async () => {
      setLoading_user(true); // Nastaví loading na true na začátku
      try {
        const result = await route_post_universal(
          urlServer,
          urlFetch,
          wordResultKey,
          state,
          wordResultKey2,
          state2
        );
        if (result.success) {
          setFetch_result(result);
          // Posun obrazovky
          window.scrollTo({
            top: 218,
          });
        } else {
          setFetch_result([]);
          setError(result.msg);
        }
      } catch (error) {
        setError(`Error: ${error.message}`);
        setFetch_result([]);
      } finally {
        setLoading_user(false); // Nastaví loading na false po dokončení
      }
    };

    if (!search) {
      fetch_data();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, state2, search]);
};

export default useFetch_colroSet_info;
import Select from "react-select";

const SelectMoreWithNull = ({ options, object, onChange, defaultOption, placeholder, menuPlacement }) => {
  let formattedOptions;

  // Pokud není objekt nebo je prázdný, použijeme volby z options
  if (!object || object.length === 0) {
    formattedOptions = options && options.length > 0 ? options.map((text, index) => ({
      value: index,
      label: text,
    })) : [];
  } else {
    // Formátování objektů pro použití v selectu
    formattedOptions = object.map((oneObject, index) => {
      const { value, label } = oneObject;

      return {
        value,
        label,
        index,
      };
    });
  }


  return (
    <>
      <Select
        options={formattedOptions}
        onChange={(selectedOption) => {
          // Pokud je vybrána možnost "Nevybráno" (null), zobrazí se placeholder
          onChange(selectedOption ? selectedOption.value : null);
        }}
        menuPlacement={menuPlacement ? menuPlacement : "auto"}
        placeholder={placeholder}
        maxMenuHeight={200} // px
        isClearable={true} // Tato volba umožňuje zrušit výběr
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: "center",
          }),
          option: (provided) => ({
            ...provided,
            textAlign: "center",
            cursor: "pointer",
          }),
        }}
        defaultValue={defaultOption || null} // Nastaví výchozí hodnotu jako null
      />
    </>
  );
}

export default SelectMoreWithNull;
const f_input_regex = (regexSetting, value) => {
    let regex;

    // Nastavení regex na základě typu
    switch (regexSetting) {
      case "text":
        regex = /^[^\d?<>.:/_*+%;¬`@&#{}()[\]]*$/;
        break;
      case "country":
        regex = /^[^?<>:/_*+%;¬`@&#{}[\]"!\\§]*$/;
        break;
      case "word":
        regex = /^[^\d?<>.:_*+\-%;¬`@&#{}()[\]]*$/;
        break;
      case "email":
        regex = /^[a-zA-Z0-9._%+-]*@{0,1}[a-zA-Z0-9.-]*[a-z]{0,5}$/;
        break;
      case "password":
        regex = /^[^ <>×÷¨°'/*§%;¬`@&#{}()[\]]*$/;
        break;
      case "name":
        regex = /^[^\d?<>.:,/_*+\-%;¬`@&#{}()[\]]{0,50}$/;
        break;
      case "tag":
      case "universal":
        regex = /^[^?<>:/_*+¬`#{}()[\]]{0,50}$/;
        break;
      case "ID":
        regex = /^[^@<>\\&]{0,50}$/;
        break;
      case "profession":
        regex = /^[^?<>._*+%;¬`@#{}[\]]*$/;
        break;
      case "phone":
        regex = /^\+[\d ]{0,19}$/;
        break;
      case "URL":
        regex = /^[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*$/;
        break;
      case "number":
        regex = /^\d+$/;
        break;
      case "CEO_code":
        regex = /^\d{0,8}(?:_(\d{0,6}(?:_[A-Za-z0-9]{0,15})?)?)?$/;
        break;
      default:
        console.warn(`Unknown regexSetting: ${regexSetting}`);
        return false;
    }
  
    // Ověření hodnoty
    return regex.test(value) || value === "";
  };
  
  export default f_input_regex;
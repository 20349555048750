import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../global/GlobalContext";
import route_universal from "../../routes/global/route_universal";

export const useOneShow_Global = ( urlFetch, key, one_ID ) => {
  const { urlServer, setError } = useContext(GlobalContext);
  const [fetch_data_one, setFetch_data_one] = useState({});

  console.log(urlFetch, key, one_ID )

  useEffect(() => {
    console.log(one_ID)
    const fetchColorSet = async () => {
      if (!one_ID) return;
      setFetch_data_one({});
      
      try {
        const result = await route_universal(
          "POST",
          urlServer,
          urlFetch,
          key,
          one_ID
        );

        if(!result.success) {
          return setError(result.error ? result.message : result.msg);
        }

        setFetch_data_one(result);
        
      } catch (error) {
        setError("Error fetching color set:", error);
      }
    };

    fetchColorSet();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [one_ID]);

  return {fetch_data_one,setFetch_data_one};
};
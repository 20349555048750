import { useState, useContext } from "react";
import { GlobalContext } from "../../../../../global/GlobalContext";
import "./ColorSet_Switch_On_Another_Tag_PopUp.scss";
import SelectTagFindAllName from "../../../selects/Specific_With_Function/Select_Tag_FindAllName/Select_Tag_FindAllName";
import route_universal from "../../../../../routes/global/route_universal";

const ColorSetSwitchOnAnotherTagPopUp = ({
  setShow, // add from `React.cloneElement`
  name,
  tag_Name,
  respondent_ID,
  // - switch to another tag - colorSet list
  setFetch_result,
  // - switch to another tag - tag list
  setFetch_tags,
  tag_ID,
  setFetch_respondents_in_tag,
  // - page colorSet - over tag - switch to another tag
  setFetch_result_ColorSet_list,

}) => {
  const { urlServer, setError } = useContext(GlobalContext);

  const [tagSelect, setTagSelect] = useState("");

  // --------------------------------------------------
  // f

  const btn_set = async () => {
    if (tagSelect.value) {
      try {
        const response = await route_universal(
          "PATCH",
          urlServer,
          "/switch_colorSet_to_another_tag",
          "colorSet_ID",
          respondent_ID,
          "newTag_ID",
          tagSelect.value
        );

        if (response.success) {
            // - page - colorSet
            if(typeof setFetch_result === "function") {
                setFetch_result((prev) => ({
                ...prev,
                fetch_data: prev.fetch_data.map((item) =>
                    item._id === respondent_ID
                    ? {
                        ...item,
                        tag: response.tag,
                        tag_info: response.tag_info,
                        tag_job: response.tag_job,
                        company: response.company
                        }
                    : item
                ),
                }));
            }
        // -----------------------------------------
          // - pages - tags
            // - tag_list
            if(typeof setFetch_tags === "function") {
              setFetch_tags((prev) => ({
                ...prev,
                fetch_data: prev.fetch_data.map(
                  (item) => {
                    // -1 in original Tag
                    if (item._id === tag_ID) {
                      return {
                        ...item,
                        colorSet_number: item.colorSet_number - 1,
                      };
                    }
                    // +1 in new Tag
                    if (item._id === tagSelect.value) {
                      return {
                        ...item,
                        colorSet_number: item.colorSet_number + 1,
                      };
                    }
                    // others return
                    return item;
                  }
                ),
              }));
            }

            if(typeof setFetch_respondents_in_tag === "function") {
                // - Respondents in Tag_list
                setFetch_respondents_in_tag((prev) => ({
                    ...prev,
                    fetch_data: prev.fetch_data.filter(
                    (item) => item._id !== respondent_ID
                    ),
                }));
            }
        // -----------------------------------------

            // - page colorSet - over tag
            if(typeof setFetch_result_ColorSet_list === "function") {
                setFetch_result_ColorSet_list((prev) => ({
                    ...prev,
                    fetch_data: prev.fetch_data.map((item) => {
                      if(item._id === respondent_ID) {
                        return {
                          ...item,
                          tag: response.tag,
                          tag_info: response.tag_info,
                          tag_job: response.tag_job,
                        }
                      }
      
                      return item;
                    })
                  }))
            }

          // - Close popUp - PopUpMedium
          setShow(false);
        }

        setError(response.error ? response.message : response.msg);
      } catch (err) {
        setError("Client - Catch: " + err);
      }
    } else {
      setError("Nový tag nebyl vybrán");
    }
  };

  // --------------------------------------------------

  return (
    <div className="ColorSet_Switch_On_Another_Tag_PopUp">
      <div className="name_box">
        <h2 className="name">{name}</h2>
        <h2 className="tag_name">{tag_Name}</h2>
      </div>

      <div className="tag_select">
        <SelectTagFindAllName setTagSelect={setTagSelect} menuPlacement="bottom" />
      </div>

      <button className="normalButton" onClick={btn_set}>
        Přesunout
      </button>
    </div>
  );
};

export default ColorSetSwitchOnAnotherTagPopUp;

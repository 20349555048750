import { NavLink } from "react-router-dom";
import { useState, useContext } from "react";
import { HiMenu } from "react-icons/hi";
import "./scss/Header.scss";
import mainLogo from "../../../img/novitim_logo_web_250.png";
import logo_dmaps from "../../../img/logo/dmaps/logo_dmaps_orange.png";
import text_dmaps from "../../../img/logo/dmaps/text/text_dmaps_white.png";
import { GlobalContext } from "../../GlobalContext";

import LogOut from "../UserData/LogOut/LogOut";
import BtnRegistration from "../UserData/Registration/BtnRegistration";

const Header = () => {
  const { userName, admin_rank, admin_company_Name } = useContext(GlobalContext);
  const [showMenu, setShowMenu] = useState(false);

  const hideMenu = () => {
    setShowMenu(false);
  };

  return (
    <header className={`${userName ? "whiteBG" : ""}`}>
      <div className="nav-header">
        <div className="logo">
          <NavLink to="/">
            <img className="logo_dmaps" src={logo_dmaps} alt="Logo Dmaps" />
            <img className="text_dmaps" src={text_dmaps} alt="Logo Text Dmaps" />
          </NavLink>
          {
            admin_company_Name === "Novitim" && 
            <div className="novitim_logo_box">
              {/* <p>pro</p> */}
              <div className="novitim_logo_box2">
                <img className="novitim_logo" src={mainLogo} alt="Novitim Logo" />
              </div>
            </div>
          }
          
        </div>

        <button
          className="humburger-button"
          onClick={() => setShowMenu(!showMenu)}
        >
          <HiMenu className="hamburger-icon" />
        </button>
      </div>

      <div className="nav-box">
        <nav className={`${showMenu ? "show" : "hide"}`}>
          {
            admin_rank <= 4 && <div className="registration" onClick={hideMenu}>
              <BtnRegistration />
            </div>
          }
        
          <div className="logOut" onClick={hideMenu}>
            <LogOut />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
